import React, { useCallback, useContext, useEffect } from 'react';
import { useConstructorMode } from '@quarkly/widgets';
import { useStore } from 'zustand';
import get from 'just-safe-get';
export const getAPI = () => {
	if (typeof window !== 'undefined') return window.QAPI || {};
	if (typeof global !== 'undefined') return global.QAPI || {};
	return {};
}; // Prevent click in constructor mode

export const useOnClickQuarklySafe = (func, deps) => {
	const mode = useConstructorMode();
	return useCallback((...args) => {
		if (mode !== 'constructor') {
			func(...args);
		}
	}, [...deps, mode]);
};

const getNumber = (value, defaultValue, validator = () => true) => {
	const parsed = parseFloat(value);
	return !Number.isNaN(parsed) && validator(parsed) ? parsed : parseFloat(defaultValue);
};

const numberTransformer = (v, d) => getNumber(v, d);

const getQuarklyColor = (rawColor, theme) => {
	const isVariable = rawColor.substring(0, 2) === '--';
	return isVariable ? theme?.color[rawColor.substring(2)] ?? rawColor : rawColor;
};

const emptyTransformer = (v, d) => v;

const getTransformer = prop => {
	if (prop.type === 'number') {
		return numberTransformer;
	}

	if (prop.control === 'color') {
		if (prop?.transform?.asValue) {
			return (v, d, theme) => {
				return getQuarklyColor(v, theme);
			};
		}
	}

	return emptyTransformer;
};

export const withPropsTransformer = Component => {
	const {
		propInfo,
		defaultProps
	} = Component;

	function WrappedComponent(props) {
		// const theme = useTheme();
		Object.keys(propInfo).forEach(p => {
			if (p in props) {
				const transformer = getTransformer(propInfo[p]); // eslint-disable-next-line no-param-reassign

				props[p] = transformer( // eslint-disable-next-line react/destructuring-assignment
				props[p], defaultProps[p] // theme
				);
			}
		}, []);
		return <Component {...props} />;
	}

	return Object.assign(WrappedComponent, Component);
};

const createFnName = name => {
	return `set${name.charAt(0).toUpperCase()}${name.slice(1)}`;
};

export const makeUseStateReplacer = set => {
	const useStateReplacer = (name, initialState) => {
		const fnName = createFnName(name);
		return {
			[name]: initialState,
			[fnName]: arg => set(state => {
				if (arg instanceof Function) {
					return {
						[name]: arg(state[name])
					};
				}

				return {
					[name]: arg
				};
			})
		};
	};

	return useStateReplacer;
};

const emptyFn = () => {};

export const makeUseStateSelector = (name, path = null) => {
	const dpath = path ? `${path}.` : '';
	const fnName = createFnName(name);

	const onlyVal = s => get(s, `${dpath}${name}`, null);

	const onlySet = s => get(s, `${dpath}${fnName}`, emptyFn);

	const selector = s => {
		return [onlyVal(s), onlySet(s)];
	};

	return Object.assign(selector, {
		onlyVal,
		onlySet
	});
};
export const makeUseStoreFromContext = Context => (selector, equalityFn) => {
	const store = useContext(Context);
	if (!store) return null;
	return useStore(store, selector, equalityFn);
};
export const useEffectQuarklySafe = (func, deps) => {
	const mode = useConstructorMode();
	return useEffect((...args) => {
		if (mode !== 'constructor') {
			return func(...args);
		}

		return () => {};
	}, [...deps, mode]);
};
export const useOnConstructorMode = (func, deps) => {
	const mode = useConstructorMode();
	return useEffect((...args) => {
		if (mode === 'constructor') {
			return func(...args);
		}

		return () => {};
	}, [...deps, mode]);
};