import React, { useCallback } from 'react';
import { Box } from '@quarkly/widgets';
import { useHistory, useLocation as useCRALocation } from 'react-router-dom';
import { navigate as gatsbyNavigate } from 'gatsby-link';
import { useLocation as useGatsbyLocation } from '@gatsbyjs/reach-router';
import { getAPI, useEffectQuarklySafe } from './Utils';
export const useNavigate = () => {
	const {
		projectType,
		mode
	} = getAPI() || {};
	if (mode === 'development') return useQuarklyPreviewNavigate(); // eslint-disable-line

	return projectType === 'gatsby' ? useGatsbyNavigate() // eslint-disable-line
	: useCRANavigate(); // eslint-disable-line
};
export const useLocation = () => {
	const {
		projectType
	} = getAPI() || {};
	return projectType === 'gatsby' ? useGatsbyLocation() : useCRALocation(); // eslint-disable-line
};

const useQuarklyPreviewNavigate = () => {
	return useCallback(dest => {
		window.location.hash = `#${dest}`;
	}, []);
};

const useCRANavigate = () => {
	const history = useHistory();
	return useCallback(dest => {
		history.push(dest);
	}, [history]);
};

const useGatsbyNavigate = () => {
	return useCallback(dest => {
		gatsbyNavigate(dest);
	}, []);
};

function Navigate({
	destination,
	...props
}) {
	const navigate = useNavigate();
	useEffectQuarklySafe(() => {
		navigate(destination);
	}, []);
	return <Box {...props} />;
}

Object.assign(Navigate, {
	propInfo: {
		destination: {
			control: 'href'
		}
	}
});
export default Navigate;