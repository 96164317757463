import { useState } from 'react';
import { FACEBOOK_PIXEL, TIKTOK_PIXEL, GTAG_ID, APPQS } from './Config';
/* eslint-disable */

const flatObject = (obj, roots = [], sep = '.') => Object // find props of given object
.keys(obj) // return an object by iterating props
.reduce((memo, prop) => Object.assign( // create a new object
{}, // include previously returned object
memo, Object.prototype.toString.call(obj[prop]) === '[object Object]' ? // keep working if value is an object
flatObject(obj[prop], roots.concat([prop]), sep) : // include current prop and value and prefix prop with the roots
//   : {[roots.concat([prop]).join(sep)]: obj[prop]}
{
	[roots.concat([prop])[0]]: `${[roots.concat([prop]).slice(1).join(sep), obj[prop]].filter(Boolean).join(sep)}`
}), {});
/* eslint-enable */


const initFbq = () => {
	if (typeof window === 'undefined') return;
	/* eslint-disable */

	!function (f, b, e, v, n, t, s) {
		if (f.fbq) return;

		n = f.fbq = function () {
			n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
		};

		if (!f._fbq) f._fbq = n;
		n.push = n;
		n.loaded = !0;
		n.version = '2.0';
		n.queue = [];
		t = b.createElement(e);
		t.async = !0;
		t.src = v;
		s = b.getElementsByTagName(e)[0];
		s.parentNode.insertBefore(t, s);
	}(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
	/* eslint-enable */

	window.fbq?.('init', FACEBOOK_PIXEL);
};

const initTtq = () => {
	if (typeof window === 'undefined') return;
	if (window.ttq) return;
	/* eslint-disable */

	!function (w, d, t) {
		w.TiktokAnalyticsObject = t;
		var ttq = w[t] = w[t] || [];
		ttq.methods = ['page', 'track', 'identify', 'instances', 'debug', 'on', 'off', 'once', 'ready', 'alias', 'group', 'enableCookie', 'disableCookie'], ttq.setAndDefer = function (t, e) {
			t[e] = function () {
				t.push([e].concat(Array.prototype.slice.call(arguments, 0)));
			};
		};

		for (var i = 0; i < ttq.methods.length; i++) ttq.setAndDefer(ttq, ttq.methods[i]);

		ttq.instance = function (t) {
			for (var e = ttq._i[t] || [], n = 0; n < ttq.methods.length; n++) ttq.setAndDefer(e, ttq.methods[n]);

			return e;
		}, ttq.load = function (e, n) {
			var i = 'https://analytics.tiktok.com/i18n/pixel/events.js';
			ttq._i = ttq._i || {}, ttq._i[e] = [], ttq._i[e]._u = i, ttq._t = ttq._t || {}, ttq._t[e] = +new Date(), ttq._o = ttq._o || {}, ttq._o[e] = n || {};
			var o = document.createElement('script');
			o.type = 'text/javascript', o.async = !0, o.src = i + '?sdkid=' + e + '&lib=' + t;
			var a = document.getElementsByTagName('script')[0];
			a.parentNode.insertBefore(o, a);
		};
	}(window, document, 'ttq');
	/* eslint-enable */

	window.ttq?.load(TIKTOK_PIXEL);
};

const initGtm = () => {
	if (typeof window === 'undefined') return;
	if (window.gtag) return;
	window.dataLayer = window.dataLayer || [];

	window.gtag = function gtag() {
		// eslint-disable-next-line prefer-rest-params
		window.dataLayer.push(arguments);
	};

	const script = document.createElement('script');
	script.type = 'text/javascript';
	script.async = true;
	script.src = `https://www.googletagmanager.com/gtag/js?id=${GTAG_ID}`;
	document.head.appendChild(script);
	window.gtag('js', new Date());
	window.gtag('config', GTAG_ID);
};

export const init = () => {
	initFbq();
	initTtq();
	initGtm();
};

const pageViewFbq = () => {
	if (window.fbq) {
		window.fbq('track', 'PageView');
	}
};

const pageViewTtq = () => {
	if (window.ttq) {
		window.ttq.page();
	}
};

export const pageView = () => {
	pageViewFbq();
	pageViewTtq();
};

const convertToAnaliticEvent = (item, data) => {
	const [{
		type
	}, fn] = item;
	const withData = [fn];

	if (type === 'gtag') {
		withData.push({
			name: data.type,
			data
		});
	} else {
		withData.push(data);
	}

	return withData;
};

const handleProviders = ({
	providers
}) => data => {
	const whithData = providers.map(item => convertToAnaliticEvent(item, data));
	whithData.forEach(([fn, d]) => fn(d));
};

const sendGTag = ({
	qs
}) => ({
	name,
	data
}) => {
	if (typeof window !== 'undefined' && window.gtag && name) {
		const flat = flatObject({ ...data,
			appqs: qs
		}); // console.log(flat);

		window.gtag('event', name, flat);
		window.gtag('event', flat.amplitude, flat);
	}
};

const sendAnalitics = ({
	qs = APPQS,
	userid
}) => {
	const providers = [[{
		type: 'gtag'
	}, sendGTag({
		qs
	})]];
	const handle = handleProviders({
		providers
	});
	return handle;
};

export const useAnalytics = () => {
	const [analyticsEvent] = useState(() => sendAnalitics({}));
	return analyticsEvent;
};