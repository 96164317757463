import React from 'react';
import atomize from '@quarkly/atomize';
import { useOverrides } from '@quarkly/components';
import { Box } from '@quarkly/widgets';
import { withPropsTransformer } from './Utils';
const Svg = atomize.svg();
const defaultProps = {
	display: 'flex',
	'flex-direction': 'column',
	'align-items': 'center',
	'text-align': 'center',
	'text-transform': 'uppercase',
	'font-family': "'SF-Pro-Text','Arial',sans-serif",
	'letter-spacing': '.1em',
	'line-height': '1.2',
	'font-size': '10px',
	'font-weight': '700',
	margin: '0',
	'grid-area': 'progress',
	// ---
	theme: 'light'
};
const overrides = {
	steps: {
		kind: 'Box',
		props: {}
	},
	title: {
		kind: 'Box',
		props: {
			display: 'flex',
			'align-items': 'center',
			gap: '7px',
			'font-size': '1.2em',
			'margin-bottom': '2px'
		}
	},
	box3: {
		kind: 'Box',
		props: {}
	},
	box4: {
		kind: 'Box',
		props: {
			width: '100%',
			height: '3px',
			position: 'relative'
		}
	},
	box5: {
		kind: 'Box',
		props: {
			width: '100%',
			height: '100%',
			content: "''",
			'z-index': '0',
			position: 'absolute',
			left: '0',
			top: '0'
		}
	},
	bar: {
		kind: 'Box',
		props: {
			'z-index': '1',
			'background-color': '#141d19',
			'transition-duration': '1.5s',
			'transition-timing-function': 'ease',
			position: 'relative',
			height: '100%'
		}
	},
	icon: {
		kind: 'Svg',
		props: {
			width: '15px',
			height: '15px',
			fill: 'currentColor'
		}
	}
};
const propInfo = {
	theme: {
		control: 'select',
		variants: ['dark', 'light']
	},
	currentStep: {
		control: 'input',
		type: 'number'
	}
};

function QuestionareProgress({
	theme,
	currentStep,
	...props
}) {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	const stepCount = 24; // TODO: Q-177

	return <Box {...rest} color={`--${theme}ContrastLower`} style={{
		gap: '4px'
	}}>
		      
		<Box {...override('steps')}>
			        
			{`STEP ${currentStep}/${stepCount}`}
			      
		</Box>
		      
		<Box {...override('title')} color={`--${theme}ContrastHigh`}>
			        
			<Box {...override('box3')} />
			        
			<Svg {...override('icon')}>
				          
				<use xlinkHref="#gps" />
				        
			</Svg>
			        
			{`QUESTION ${currentStep}`}
			      
		</Box>
		      
		<Box {...override('box4')}>
			        
			<Box {...override('box5')} background={`--color-${theme}ProgressTrack`} />
			        
			<Box {...override('bar')} style={{
				width: `${currentStep / stepCount * 100}%`
			}} />
			      
		</Box>
		      
		{children}
		    
	</Box>;
}

Object.assign(QuestionareProgress, { ...Box,
	propInfo,
	defaultProps,
	overrides
});
export default withPropsTransformer(QuestionareProgress);