import React from 'react';
import atomize from '@quarkly/atomize';

function SvgIcon({
	icon,
	...props
}) {
	return <svg {...props}>
		      
		<use xlinkHref={`#${icon}`} />
		    
	</svg>;
}

export default atomize(SvgIcon)({
	name: 'SvgIcon',
	effects: {
		hover: ':hover'
	},
	normalize: true,
	mixins: true,
	description: {
		// paste here description for your component
		en: 'SvgIcon — my awesome component'
	},
	propInfo: {
		// paste here props description for your component
		icon: {
			control: 'input'
		},
		fill: {
			control: 'color'
		},
		stroke: {
			control: 'color'
		}
	}
});