import React, { useRef, useEffect, useState } from 'react';
import { useOverrides } from '@quarkly/components';
import { Box } from '@quarkly/widgets';
import atomize from '@quarkly/atomize';
import { createStore } from 'zustand';
import { withPropsTransformer } from './Utils';
const propInfo = {
	size: {
		title: 'Size',
		type: 'number'
	},
	progress: {
		title: 'Progress',
		type: 'number'
	},
	trackWidth: {
		title: 'Track Width',
		type: 'number'
	},
	indicatorWidth: {
		title: 'Indicator Width',
		type: 'number'
	},
	trackColor: {
		title: 'Track Color',
		control: 'color',
		transform: {
			asValue: true
		}
	},
	indicatorColor: {
		title: 'Indicator Color',
		control: 'color',
		transform: {
			asValue: true
		}
	}
};
const defaultProps = {
	'min-width': '10px',
	'min-height': '10px',
	position: 'relative',
	//
	// size: '180',
	progress: 0,
	trackWidth: 10,
	trackColor: '#ECECEC',
	indicatorWidth: 10,
	indicatorColor: '--btn',
	indicatorCap: 'round',
	labelColor: '--btn',
	spinnerMode: false,
	spinnerSpeed: 1
};
const overrides = {
	Label: {
		kind: 'Box',
		props: {
			position: 'absolute',
			top: '50%',
			left: '50%',
			transform: 'translate(-50%, -50%)',
			'font-weight': '700',
			'font-size': '40px',
			'line-height': '52px',
			'text-align': 'center',
			color: '--btn'
		}
	}
};
const SVG = atomize.svg({
	useAliases: false
}, {
	transform: 'rotate(-90deg)'
});
const Circle = atomize.circle({
	useAliases: false
});

function AppCircularProgress(props) {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	const {
		progress,
		trackWidth,
		trackColor,
		indicatorWidth,
		indicatorColor,
		indicatorCap,
		labelColor,
		spinnerMode,
		spinnerSpeed
	} = props;
	const ref = useRef(null);
	const radius = 50;
	const dashArray = 2 * Math.PI * radius;
	const dashOffset = dashArray * ((100 - progress) / 100);
	return <Box ref={ref} {...rest}>
		      
		<SVG width="100%" height="100%" viewBox="0 0 110 110">
			        
			<Circle
				cx={55}
				cy={55}
				fill="transparent"
				r={radius}
				stroke={trackColor}
				strokeWidth={trackWidth}
			/>
			        
			<Circle
				style={{
					animationDuration: spinnerSpeed * 1000
				}}
				cx={55}
				cy={55}
				fill="transparent"
				r={radius}
				stroke={indicatorColor}
				strokeWidth={indicatorWidth}
				strokeDasharray={dashArray}
				strokeDashoffset={dashOffset}
				strokeLinecap={indicatorCap}
			/>
			      
		</SVG>
		      
		<Box {...override('Label')}>
			        
			{!spinnerMode && <span>
				            
				{`${progress > 100 ? 100 : progress}%`}
				          
			</span>}
			      
		</Box>
		    
	</Box>;
}

Object.assign(AppCircularProgress, { ...Box,
	defaultProps,
	propInfo,
	overrides
});
export default withPropsTransformer(AppCircularProgress);