import { Box } from '@quarkly/widgets';
import React from 'react';
import { useEffectQuarklySafe } from './Utils';
import { init, pageView } from './Analytics'; // import { useNavigate } from './Navigate';

function PageWrapper({
	children,
	...props
}) {
	useEffectQuarklySafe(() => {
		async function run() {
			/*
   Редирект?
   */
			init();
			pageView();
		}

		run();
	}, []);
	return <Box display="contents" {...props}>
		      
		{children}
		    
	</Box>;
}

export default PageWrapper;