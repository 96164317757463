import React from 'react';
import { useOverrides } from '@quarkly/components';
import { Box, Text } from '@quarkly/widgets';
import SvgIcon from './SvgIcon';
import { progressSelector, useAnalyzingTheAnswersPageStore } from './AnalyzingTheAnswersPageWrapper';
import { withPropsTransformer } from './Utils';
const propInfo = {
	thresholdValue: {
		type: 'number'
	}
};
const defaultProps = {
	display: 'flex',
	'flex-direction': 'row',
	'align-items': 'center',
	'grid-gap': '12px',
	margin: '18px 0px 0px 0px',
	thresholdValue: 100
};
const overrides = {
	box: {
		kind: 'Box',
		props: {
			width: '21px',
			height: '21px',
			flex: '0 0 auto',
			'border-radius': '50%',
			'transition-duration': '.2s',
			'justify-content': 'center',
			'flex-shrink': '0',
			display: 'flex',
			'align-items': 'center',
			background: 'transparent'
		}
	},
	'box checked': {
		props: {
			background: '--color-darkBgHigh'
		}
	},
	svgIcon: {
		kind: 'SvgIcon',
		props: {
			icon: 'check-mark-mitter',
			width: '60%',
			height: '60%',
			fill: '#CBDCB5'
		}
	},
	text: {
		kind: 'Text',
		props: {
			font: "700 14px 'New York Small','Times New Roman',serif",
			'grid-gap': '14px',
			color: '--lightContrastHigh',
			margin: '0px',
			children: 'Intimate health summary'
		}
	},
	'text checked': {
		props: {
			color: '--lightContrastHigh'
		}
	}
};

function AnalyzingTheAnswersStatus({
	thresholdValue,
	...props
}) {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	const progress = useAnalyzingTheAnswersPageStore(progressSelector.onlyVal);
	const isChecked = progress >= thresholdValue;
	return <Box {...rest}>
		      
		<Box {...override('box', isChecked && 'box checked')}>
			        
			<SvgIcon {...override('svgIcon', isChecked && 'isChecked checked')} />
			      
		</Box>
		      
		<Text {...override('text', isChecked && 'text checked')} />
		      
		{children}
		    
	</Box>;
}

Object.assign(AnalyzingTheAnswersStatus, { ...Box,
	propInfo,
	defaultProps,
	overrides
});
export default withPropsTransformer(AnalyzingTheAnswersStatus);