import React from 'react';
import atomize from '@quarkly/atomize';

const SvgProvider = props => <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
	    
	<defs>
		      
		<clipPath id="clip0_3026_3">
			        
			<rect width={24} height={24} fill="white" />
			      
		</clipPath>
		      
		<clipPath id="clip0_2705_8428">
			        
			<rect
				width={28.410999}
				height={39.905998}
				transform="matrix(0.988202,0.153157,-0.223866,0.97462,8.93359,0.280273)"
				id="rect38"
				x={0}
				y={0}
				style={{
					fill: '#ffffff'
				}}
			/>
			      
		</clipPath>
		    
	</defs>
	    
	<symbol id="apple" viewBox="0 0 17 20">
		      
		<path d="M13.669 10.4167C13.669 8.62376 14.701 7.0755 16.2013 6.32233C14.862 5.068 13.1412 5 12.8355 5C12.0227 5 11.4428 5.22013 10.8824 5.43294C10.3394 5.63924 9.82665 5.83374 9.08472 5.83374C8.3428 5.83374 7.83 5.63924 7.28709 5.43294C6.72668 5.22013 6.14673 5 5.33399 5C4.82201 5 0.333008 5.16357 0.333008 10.8337C0.333008 15.4704 3.49728 20 5.33399 20C6.45848 20 7.10517 19.7371 7.67616 19.5052C8.12221 19.3241 8.50803 19.1675 9.08472 19.1675C9.66142 19.1675 10.0472 19.3241 10.4933 19.5056C11.0643 19.7371 11.711 20 12.8351 20C14.1218 20 15.9542 17.7741 16.9997 14.8485C15.0261 14.3373 13.669 12.5497 13.669 10.4167ZM12.8352 0C10.3938 0.49998 8.64386 2.51241 8.66823 5C11.1096 4.49997 12.8596 2.48759 12.8352 0Z" />
		    
	</symbol>
	    
	<symbol id="arrow-right" viewBox="0 0 16 12">
		      
		<path d="M10,0L8.6,1.4L12.2,5H0v2h12.2l-3.6,3.6L10,12l6-6L10,0z" />
		    
	</symbol>
	    
	<symbol id="benefits-book" viewBox="0 0 32 32">
		      
		<path d="M13.9,3.3v25.5h14.8V3.3H13.9z M20.6,27.3h-5.2V4.8h5.2V27.3z M27.3,27.3h-5.2V4.8h5.2V27.3z" />
		      
		<path d="M10.3,28.8L3.2,28L6,3.2L12.8,4L10.3,28.8z M4.8,26.7L9,27.2l2.2-21.8L7.3,4.8L4.8,26.7z" />
		      
		<rect x={23.9} y={10} width={1.5} height={2} />
		      
		<rect x={17.3} y={10} width={1.5} height={2} />
		    
	</symbol>
	    
	<symbol id="benefits-coin" viewBox="0 0 32 32">
		      
		<path d="M22.7,11.9c-2.4,0-4.6,0.6-5.9,1.6V6.7c0-2.3-3.3-4.1-7.4-4.1c-4.2,0-7.4,1.8-7.4,4.1v18.7 c0,2.3,3.3,4.1,7.4,4.1c3,0,5.5-0.9,6.7-2.3c1.2,1.4,3.7,2.3,6.7,2.3c4.2,0,7.4-1.8,7.4-4.1V16C30.1,13.7,26.8,11.9,22.7,11.9z  M15.3,25.3c0,1.2-2.4,2.6-5.9,2.6c-3.5,0-5.9-1.4-5.9-2.6v-2.2c1.4,1,3.5,1.6,5.9,1.6s4.6-0.6,5.9-1.6V25.3z M15.3,20.7 c0,1.2-2.4,2.6-5.9,2.6c-3.5,0-5.9-1.4-5.9-2.6v-2.2c1.4,1,3.5,1.6,5.9,1.6s4.6-0.6,5.9-1.6V20.7z M15.3,16c0,1.2-2.4,2.6-5.9,2.6 c-3.5,0-5.9-1.4-5.9-2.6v-2.2c1.4,1,3.5,1.6,5.9,1.6s4.6-0.6,5.9-1.6V16z M15.3,11.3c0,1.2-2.4,2.6-5.9,2.6c-3.5,0-5.9-1.4-5.9-2.6 V9.2c1.4,1,3.5,1.6,5.9,1.6s4.6-0.6,5.9-1.6V11.3z M9.3,9.3c-3.5,0-5.9-1.4-5.9-2.6c0-1.2,2.4-2.6,5.9-2.6c3.5,0,5.9,1.4,5.9,2.6 C15.3,7.9,12.8,9.3,9.3,9.3z M22.7,13.4c3.5,0,5.9,1.4,5.9,2.6s-2.4,2.6-5.9,2.6c-3.5,0-5.9-1.4-5.9-2.6S19.2,13.4,22.7,13.4z  M28.6,25.3c0,1.2-2.4,2.6-5.9,2.6c-3.5,0-5.9-1.4-5.9-2.6v-2.2c1.3,1,3.5,1.6,5.9,1.6s4.6-0.6,5.9-1.6V25.3z M22.7,23.3 c-3.5,0-5.9-1.4-5.9-2.6v-2.2c1.3,1,3.5,1.6,5.9,1.6s4.6-0.6,5.9-1.6v2.2C28.6,21.9,26.2,23.3,22.7,23.3z" />
		    
	</symbol>
	    
	<symbol id="benefits-play" viewBox="0 0 32 32">
		      
		<path d="M25.4,6.6V3.9H6.6v2.7H1.9v18.8h4.7v2.7h18.8v-2.7h4.7V6.6H25.4z M3.4,23.9V8.1h3.2v15.8H3.4z M23.9,26.6H8.1 V5.4h15.8V26.6z M28.6,23.9h-3.2V8.1h3.2V23.9z" />
		      
		<path d="M13.9,20.1v-8.1L20,16L13.9,20.1z M15.4,14.7v2.5l1.9-1.3L15.4,14.7z" />
		    
	</symbol>
	    
	<symbol id="benefits-puzle" viewBox="0 0 30 30">
		      
		<path d="M22.4,29.1H0.9v-6.8h3.4c1.8,0,3.3-1.5,3.3-3.3s-1.5-3.3-3.3-3.3H0.9V6.3h6V5.7C6.9,3,9,0.9,11.7,0.9 s4.8,2.1,4.8,4.8v0.6h6v8h1.9c2.6,0,4.8,2.1,4.8,4.8s-2.1,4.8-4.8,4.8h-1.9V29.1z M2.4,27.6h18.5v-5.3h3.4c1.8,0,3.3-1.5,3.3-3.3 s-1.5-3.3-3.3-3.3h-3.4v-8h-6V5.7c0-1.8-1.5-3.3-3.3-3.3S8.4,3.9,8.4,5.7v2.1h-6v6.5h1.9c2.6,0,4.8,2.1,4.8,4.8S7,23.8,4.3,23.8H2.4 V27.6z" />
		    
	</symbol>
	    
	<symbol id="check-mark-green" viewBox="0 0 18 13">
		      
		<path d="M1 5.07692L6.95455 11L17 1" stroke="#208357" strokeWidth={2} strokeLinecap="round" />
		    
	</symbol>
	    
	<symbol id="check-mark-mitter" viewBox="0 0 9.9 7.7">
		      
		<polygon points="9.9,1.6 3.8,7.7 0,3.8 1.6,2.3 3.8,4.5 8.3,0 " />
		    
	</symbol>
	    
	<symbol id="check-mark" viewBox="0 0 17 12">
		      
		<path d="M6,12c-0.3,0-0.5-0.1-0.7-0.3l-5-5c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0L6,9.6l9.3-9.3c0.4-0.4,1-0.4,1.4,0 s0.4,1,0,1.4l-10,10C6.5,11.9,6.3,12,6,12z" />
		    
	</symbol>
	    
	<symbol id="chevron-left" viewBox="0 0 9 14">
		      
		<path d="M8,14c-0.3,0-0.5-0.1-0.7-0.3L0.6,7l6.7-6.7c0.4-0.4,1-0.4,1.4,0s0.4,1,0,1.4L3.4,7l5.3,5.3 c0.4,0.4,0.4,1,0,1.4C8.5,13.9,8.3,14,8,14z" />
		    
	</symbol>
	    
	<symbol id="close" viewBox="0 0 21 21">
		      
		<path d="M20.6,18.4c0.6,0.6,0.6,1.5,0,2.1c-0.3,0.3-0.7,0.4-1.1,0.4s-0.8-0.2-1.1-0.4l-7.9-7.9l-7.9,7.9C2.3,20.8,1.9,21,1.5,21 s-0.8-0.2-1.1-0.4c-0.6-0.6-0.6-1.5,0-2.1l7.9-7.9L0.4,2.6C-0.1,2-0.1,1,0.4,0.4C1-0.1,2-0.1,2.6,0.4l7.9,7.9l7.9-7.9 c0.6-0.6,1.5-0.6,2.1,0c0.6,0.6,0.6,1.5,0,2.1l-7.9,7.9L20.6,18.4z" />
		    
	</symbol>
	    
	<symbol id="coins" viewBox="0 0 38 33">
		      
		<ellipse
			cx={10}
			cy={19.5}
			rx={10}
			ry={7.5}
			fill="#FF6D03"
		/>
		      
		<ellipse
			cx={10}
			cy={18.5}
			rx={10}
			ry={6.5}
			fill="#FFA621"
		/>
		      
		<ellipse
			cx={28}
			cy={25.5}
			rx={10}
			ry={7.5}
			fill="#FF6D03"
		/>
		      
		<ellipse
			cx={28}
			cy={24.5}
			rx={10}
			ry={6.5}
			fill="#FFA621"
		/>
		      
		<ellipse
			cx={10}
			cy={15.5}
			rx={10}
			ry={7.5}
			fill="#FF6D03"
		/>
		      
		<ellipse
			cx={10}
			cy={14.5}
			rx={10}
			ry={6.5}
			fill="#FFA621"
		/>
		      
		<ellipse
			cx={28}
			cy={21.5}
			rx={10}
			ry={7.5}
			fill="#FF6D03"
		/>
		      
		<ellipse
			cx={28}
			cy={20.5}
			rx={10}
			ry={6.5}
			fill="#FFA621"
		/>
		      
		<ellipse
			cx={10}
			cy={11.5}
			rx={10}
			ry={7.5}
			fill="#FF6D03"
		/>
		      
		<ellipse
			cx={10}
			cy={10.5}
			rx={10}
			ry={6.5}
			fill="#FFA621"
		/>
		      
		<ellipse
			cx={10}
			cy={7.5}
			rx={10}
			ry={7.5}
			fill="#FF6D03"
		/>
		      
		<ellipse
			cx={10}
			cy={6.5}
			rx={10}
			ry={6.5}
			fill="#FFA621"
		/>
		    
	</symbol>
	    
	<symbol id="exclamation" viewBox="0 0 216 213">
		      
		<path d="M131 -49C105.091 -49 79.7632 -41.317 58.2203 -26.9225C36.6775 -12.5281 19.8869 7.93134 9.97184 31.8685C0.05676 55.8056 -2.53748 82.1453 2.51719 107.557C7.57185 132.968 20.0484 156.31 38.3691 174.631C56.6897 192.952 80.0317 205.428 105.443 210.483C130.855 215.537 157.194 212.943 181.132 203.028C205.069 193.113 225.528 176.322 239.923 154.78C254.317 133.237 262 107.909 262 82C262 64.7968 258.612 47.7621 252.028 31.8685C245.445 15.9748 235.795 1.53347 223.631 -10.631C211.467 -22.7955 197.025 -32.4449 181.132 -39.0282C165.238 -45.6116 148.203 -49 131 -49ZM131 160.6C128.202 160.6 125.466 159.77 123.14 158.216C120.813 156.661 119 154.451 117.929 151.866C116.858 149.281 116.578 146.436 117.124 143.692C117.67 140.947 119.017 138.426 120.996 136.448C122.975 134.469 125.495 133.122 128.24 132.576C130.984 132.03 133.829 132.31 136.414 133.381C138.999 134.452 141.209 136.265 142.764 138.592C144.318 140.918 145.148 143.654 145.148 146.452C145.148 150.204 143.657 153.803 141.004 156.456C138.351 159.109 134.752 160.6 131 160.6ZM145.148 99.292C145.148 103.044 143.657 106.643 141.004 109.296C138.351 111.949 134.752 113.44 131 113.44C127.248 113.44 123.649 111.949 120.996 109.296C118.343 106.643 116.852 103.044 116.852 99.292V17.548C116.852 13.7957 118.343 10.1971 120.996 7.54384C123.649 4.89058 127.248 3.39999 131 3.39999C134.752 3.39999 138.351 4.89058 141.004 7.54384C143.657 10.1971 145.148 13.7957 145.148 17.548V99.292Z" />
		    
	</symbol>
	    
	<symbol id="fire" viewBox="0 0 124 201">
		      
		<path fillRule="evenodd" clipRule="evenodd" d="M7.95302 99.3684C9.91478 112.53 15.1461 125.033 29.5324 132.272C12.5305 102.001 20.3775 71.0715 42.6108 58.5681C40.649 62.5166 38.0333 71.0715 43.2647 75.0199C62.2284 55.9359 66.1519 25.0066 62.2284 0C85.1156 21.7163 98.194 52.6455 83.1538 80.9425C73.345 99.3684 89.0391 118.452 98.194 80.2844C115.196 100.026 127.62 128.323 123.043 154.646C111.272 214.531 18.4157 216.505 2.72166 159.911C-2.5097 141.485 0.105983 119.769 7.95302 99.3684Z" fill="#DFE0DF" />
		    
	</symbol>
	    
	<symbol id="flag" viewBox="0 0 44 51">
		      
		<path d="M0 0H44V45L22 51L0 45V0Z" fill="#FF6D03" />
		    
	</symbol>
	    
	<symbol id="gps" viewBox="0 0 16 16">
		      
		<path d="M8 5.33317C6.52666 5.33317 5.33333 6.5265 5.33333 7.99984C5.33333 9.47317 6.52666 10.6665 8 10.6665C9.47333 10.6665 10.6667 9.47317 10.6667 7.99984C10.6667 6.5265 9.47333 5.33317 8 5.33317ZM13.96 7.33317C13.6533 4.55317 11.4467 2.3465 8.66666 2.03984V0.666504H7.33333V2.03984C4.55333 2.3465 2.34666 4.55317 2.04 7.33317H0.666664V8.6665H2.04C2.34666 11.4465 4.55333 13.6532 7.33333 13.9598V15.3332H8.66666V13.9598C11.4467 13.6532 13.6533 11.4465 13.96 8.6665H15.3333V7.33317H13.96ZM8 12.6665C5.42 12.6665 3.33333 10.5798 3.33333 7.99984C3.33333 5.41984 5.42 3.33317 8 3.33317C10.58 3.33317 12.6667 5.41984 12.6667 7.99984C12.6667 10.5798 10.58 12.6665 8 12.6665Z" />
		    
	</symbol>
	    
	<symbol id="ic_Glyph_Bicep" viewBox="0 0 24 24">
		      
		<path d="M18.9416 4.52085C18.8918 4.15116 18.6761 3.82767 18.3566 3.63441C16.1287 2.2817 13.6768 2.02962 12.4404 2.00023C12.0961 1.99182 11.7808 2.21029 11.6688 2.54216C11.3991 3.36136 11.2995 4.24357 11.2663 4.85693C11.2456 5.18039 11.4157 5.48708 11.7019 5.63411L14.855 7.2431L15.8466 7.10867C16.0416 7.08346 16.2324 7.18849 16.3154 7.37334C16.8382 8.55382 16.0209 9.88134 15.1537 11.2929C14.5356 12.2927 13.905 13.3178 13.6228 14.3638C13.6187 14.4226 13.6063 14.4815 13.5772 14.5361C13.2744 15.8594 13.5399 17.2331 15.0791 18.6909C15.2658 18.8631 15.2741 19.153 15.104 19.3378C14.938 19.5227 14.6476 19.5311 14.4692 19.3631C12.6645 17.6532 12.3616 15.977 12.6894 14.4395C12.2952 13.9185 10.756 12.1751 7.85187 12.1751C6.32927 12.1751 5.25887 13.0741 4.6324 13.8261C3.30478 15.4225 2.83183 17.5776 3.05171 18.8883C3.33797 20.5772 3.91882 21.7114 4.83153 22.35C6.27948 23.3708 8.39121 22.9633 10.0881 22.6399C11.1584 22.434 12.0753 22.2576 12.7309 22.4382C14.0792 22.8121 16.61 22.5811 18.0081 22.413C18.5599 22.35 19.0163 21.9467 19.1657 21.4048L19.1988 21.2829C19.3814 20.5772 19.6884 19.8462 20.1572 18.9892C21.3645 16.792 21.1322 12.3767 20.3522 10.7678C19.6386 9.29321 19.1366 6.01221 18.9416 4.52085Z" />
		    
	</symbol>
	    
	<symbol id="ic_Glyph_Calendar" viewBox="0 0 24 24">
		      
		<path d="M17 4H21C21.2652 4 21.5196 4.10536 21.7071 4.29289C21.8946 4.48043 22 4.73478 22 5V21C22 21.2652 21.8946 21.5196 21.7071 21.7071C21.5196 21.8946 21.2652 22 21 22H3C2.73478 22 2.48043 21.8946 2.29289 21.7071C2.10536 21.5196 2 21.2652 2 21V5C2 4.73478 2.10536 4.48043 2.29289 4.29289C2.48043 4.10536 2.73478 4 3 4H7V2H9V4H15V2H17V4ZM4 10V20H20V10H4ZM6 14H11V18H6V14Z" />
		    
	</symbol>
	    
	<symbol id="ic_Glyph_Heart" viewBox="0 0 24 24">
		      
		<path fillRule="evenodd" clipRule="evenodd" d="M16.0884 3C19.3533 3 22 5.72385 22 9.08343C22 10.7483 21.3482 12.2578 20.2927 13.3572C20.2539 13.4049 20.2123 13.4529 20.1685 13.4978L13.4671 20.3948C13.0868 20.7852 12.5814 21 12.0435 21H11.9837C11.4435 21 10.9374 20.7835 10.5585 20.392L3.98636 13.6301C2.76833 12.5149 2 10.8914 2 9.08367C2 5.72386 4.64689 3 7.9125 3C9.49849 3 10.9378 3.64465 11.9997 4.69236C13.0612 3.64465 14.5011 3 16.0884 3ZM10.427 7.72106C10.3088 7.31435 9.94731 7.02617 9.5245 7.00168C9.1017 6.97718 8.70931 7.22168 8.545 7.61202L7.93596 9.05883H6C5.44772 9.05883 5 9.50654 5 10.0588C5 10.6111 5.44772 11.0588 6 11.0588H8.6C8.84124 11.0588 9.0684 10.9721 9.24528 10.8228L10.5397 15.2789C10.6638 15.7061 11.0551 16 11.5 16C11.9449 16 12.3362 15.7061 12.4603 15.2789L13.7065 10.9888L14.345 12.5056C14.5011 12.8765 14.8643 13.1177 15.2667 13.1177H18C18.5523 13.1177 19 12.6699 19 12.1177C19 11.5654 18.5523 11.1177 18 11.1177H15.9307L14.455 7.61202C14.2907 7.22168 13.8983 6.97718 13.4755 7.00168C13.0527 7.02617 12.6912 7.31435 12.573 7.72106L11.5 11.4151L10.427 7.72106Z" />
		    
	</symbol>
	    
	<symbol id="ic_Glyph_Lock" viewBox="0 0 20 20">
		      
		<g>
			        
			<path d="M16.7,8.3H15V5.8c0-2.8-2.2-5-5-5s-5,2.3-5,5v2.5H3.3c-0.9,0-1.7,0.8-1.7,1.7v7.5c0,0.9,0.7,1.7,1.7,1.7h13.3 c0.9,0,1.7-0.8,1.7-1.7V10C18.3,9.1,17.6,8.3,16.7,8.3z M6.7,5.8C6.7,4,8.2,2.5,10,2.5s3.3,1.5,3.3,3.3v2.5H6.7V5.8z M10.8,15 c0,0.5-0.4,0.8-0.8,0.8S9.2,15.5,9.2,15v-2.5c0-0.5,0.4-0.8,0.8-0.8s0.8,0.4,0.8,0.8V15z" />
			      
		</g>
		    
	</symbol>
	    
	<symbol id="ic_Glyph_Man_Sign" viewBox="0 0 24 24">
		      
		<path fillRule="evenodd" clipRule="evenodd" d="M22.4297 2.0665C22.3375 1.84192 22.1594 1.66317 21.9357 1.57058C21.8244 1.52383 21.7057 1.5 21.587 1.5L16.1087 1.5C15.6038 1.5 15.1957 1.91067 15.1957 2.41667C15.1957 2.92267 15.6038 3.33333 16.1087 3.33333H19.3829L15.8823 6.84783C14.3949 5.79275 12.5862 5.16667 10.6304 5.16667C5.59591 5.16667 1.5 9.27883 1.5 14.3333C1.5 19.3878 5.59591 23.5 10.6304 23.5C15.665 23.5 19.7609 19.3878 19.7609 14.3333C19.7609 11.9023 18.8067 9.69592 17.2637 8.05325L20.6739 4.6295V7.91667C20.6739 8.42267 21.082 8.83333 21.587 8.83333C22.0919 8.83333 22.5 8.42267 22.5 7.91667V2.41667C22.5 2.2975 22.4763 2.17833 22.4297 2.0665Z" />
		    
	</symbol>
	    
	<symbol id="ic_Glyph_Shield_Plus" viewBox="0 0 24 24">
		      
		<path fillRule="evenodd" clipRule="evenodd" d="M21 5.97619C17.8609 4.81385 14.8413 3.22375 12 1C9.15867 3.22375 6.1391 4.81385 3 5.97619C3 17.1813 7.3429 19.9886 11.4445 22.6399C11.6303 22.76 11.8156 22.8798 12 23C12.1844 22.8798 12.3697 22.76 12.5555 22.6399C16.6571 19.9886 21 17.1813 21 5.97619ZM13 8C13 7.44772 12.5523 7 12 7C11.4477 7 11 7.44772 11 8V10H9C8.44772 10 8 10.4477 8 11C8 11.5523 8.44772 12 9 12H11V14C11 14.5523 11.4477 15 12 15C12.5523 15 13 14.5523 13 14V12H15C15.5523 12 16 11.5523 16 11C16 10.4477 15.5523 10 15 10H13V8Z" />
		    
	</symbol>
	    
	<symbol id="ic_Glyph_Smile" viewBox="0 0 24 24">
		      
		<path d="M12,1C5.9,1,1,5.9,1,12s4.9,11,11,11s11-4.9,11-11S18.1,1,12,1z M8.5,7c1.7,0,3,1.3,3,3s-1.3,3-3,3s-3-1.3-3-3 S6.8,7,8.5,7z M8,14.6c0.5-0.3,1.1,0,1.3,0.4c0,0,0.8,1.5,2.6,1.5c1.9,0,2.6-1.4,2.6-1.5c0.2-0.5,0.8-0.7,1.3-0.4 c0.5,0.2,0.7,0.8,0.4,1.3c0,0.1-1.3,2.5-4.4,2.5S7.7,16,7.6,16C7.4,15.5,7.6,14.8,8,14.6z M15.5,13c-1.7,0-3-1.3-3-3s1.3-3,3-3 s3,1.3,3,3S17.2,13,15.5,13z" />
		    
	</symbol>
	    
	<symbol id="ic_Glyph_question" viewBox="0 0 24 24">
		      
		<path fillRule="evenodd" clipRule="evenodd" d="M20.7867 18.3165C20.9893 18.9021 21.2413 19.2296 21.5851 19.6377C22.2331 20.4078 22.2079 21.2759 20.8897 21.5052C15.5833 22.4074 11.7722 21.8859 9.79457 21.433C9.28377 21.3311 8.78737 21.1722 8.30897 20.9918C8.26737 20.9764 8.24457 20.9344 8.24457 20.9344H8.24637C4.59619 19.5139 2 15.9826 2 11.7859C2 6.36331 6.33298 2.0002 11.6766 2C17.0201 2 21.3531 6.39558 21.3531 11.8184C21.3531 12.9048 21.1763 13.9487 20.8549 14.9254L20.8577 14.9235C20.8577 14.9235 20.3677 16.7355 20.7867 18.3165ZM15.428 11.2147C15.222 11.5117 14.833 11.8772 14.261 12.3342L13.689 12.7682C13.3686 13.0195 13.1627 13.2937 13.0712 13.6135C13.0712 13.625 13.0654 13.6421 13.0597 13.6592L13.0597 13.6592C13.054 13.6764 13.0483 13.6935 13.0483 13.7049C12.9339 14.2075 12.4991 14.5731 11.9957 14.5731H11.95C11.2864 14.5731 10.783 13.9791 10.8974 13.3394C10.9432 13.0652 10.9889 12.8596 11.0576 12.6997C11.1949 12.357 11.561 11.9458 12.133 11.5117L12.7279 11.0548C12.911 10.9177 13.0712 10.7578 13.1856 10.575C13.7118 9.79824 13.5974 8.70163 12.7508 8.17617C12.0872 7.76494 10.9432 7.90202 10.5084 8.61025C10.394 8.79302 10.3025 8.97578 10.2567 9.15855C10.0966 9.61548 9.63892 9.91247 9.15841 9.91247C8.38043 9.91247 7.8084 9.15855 8.06009 8.40463C8.31179 7.65071 8.76942 7.05671 9.4101 6.64548C10.9661 5.6631 13.3 5.84587 14.7873 6.89679C15.5881 7.46794 16 8.33609 16 9.4784C15.9542 10.1409 15.7712 10.7349 15.428 11.2147ZM11.95 18H11.8584C11.2177 18 10.6915 17.4745 10.6915 16.8348C10.6915 16.1952 11.2177 15.6697 11.8584 15.6697H11.95C12.5906 15.6697 13.1169 16.1952 13.1169 16.8348C13.1169 17.4745 12.5906 18 11.95 18Z" />
		    
	</symbol>
	    
	<symbol id="ic_Outline_Library" viewBox="0 0 24 24">
		      
		<g clipPath="url(#clip0_3026_3)">
			        
			<path d="M20.5 5H6.5V22H20.5V5Z" strokeWidth={2} strokeLinejoin="round" />
			        
			<path d="M17.5 5V2H4C3.72386 2 3.5 2.22386 3.5 2.5V19H6.5" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
			        
			<path d="M10.5 11H16.5" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
			        
			<path d="M10.5 15H16.5" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
			      
		</g>
		    
	</symbol>
	    
	<symbol id="ic_Outline_Modarate" viewBox="0 0 24 24">
		      
		<path d="M18,21c-0.6,0-1-0.4-1-1V10c0-0.6,0.4-1,1-1s1,0.4,1,1v10C19,20.6,18.6,21,18,21z" />
		      
		<path d="M12,21c-0.6,0-1-0.4-1-1V4c0-0.6,0.4-1,1-1s1,0.4,1,1v16C13,20.6,12.6,21,12,21z" />
		      
		<path d="M6,21c-0.6,0-1-0.4-1-1v-6c0-0.6,0.4-1,1-1s1,0.4,1,1v6C7,20.6,6.6,21,6,21z" />
		    
	</symbol>
	    
	<symbol id="ic_Outline_Time" viewBox="0 0 24 24">
		      
		<g>
			        
			<path d="M12 22C17.5229 22 22 17.5229 22 12C22 6.47715 17.5229 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5229 6.47715 22 12 22Z" strokeWidth={2} strokeLinejoin="round" />
			        
			<path d="M12.0041 6L12.0035 12.0044L16.2432 16.2441" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
			      
		</g>
		    
	</symbol>
	    
	<symbol id="ic_Outline_check-one" viewBox="0 0 24 24">
		      
		<path d="M12,23c-2.9,0-5.7-1.1-7.8-3.2C2.1,17.7,1,14.9,1,12c0-2.9,1.1-5.7,3.2-7.8C6.3,2.1,9.1,1,12,1 c2.9,0,5.7,1.1,7.8,3.2C21.9,6.3,23,9.1,23,12c0,2.9-1.1,5.7-3.2,7.8S14.9,23,12,23z M12,3C9.6,3,7.3,3.9,5.6,5.6 C3.9,7.3,3,9.6,3,12c0,2.4,0.9,4.7,2.6,6.4c1.7,1.7,4,2.6,6.4,2.6c2.4,0,4.7-0.9,6.4-2.6c1.7-1.7,2.6-4,2.6-6.4 c0-2.4-0.9-4.7-2.6-6.4C16.7,3.9,14.4,3,12,3z" />
		      
		<path d="M11,16c-0.3,0-0.5-0.1-0.7-0.3l-3-3c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0l2.3,2.3l5.3-5.3c0.4-0.4,1-0.4,1.4,0 s0.4,1,0,1.4l-6,6C11.5,15.9,11.3,16,11,16z" />
		    
	</symbol>
	    
	<symbol id="ic_Outline_info" viewBox="0 0 24 24">
		      
		<path d="M19.8,4.2C17.7,2.1,14.9,1,12,1S6.3,2.1,4.2,4.2C2.1,6.3,1,9.1,1,12s1.1,5.7,3.2,7.8C6.3,21.9,9.1,23,12,23 s5.7-1.1,7.8-3.2c2.1-2.1,3.2-4.8,3.2-7.8S21.9,6.3,19.8,4.2z M18.4,18.4c-1.7,1.7-4,2.6-6.4,2.6s-4.7-0.9-6.4-2.6S3,14.4,3,12 s0.9-4.7,2.6-6.4S9.6,3,12,3s4.7,0.9,6.4,2.6S21,9.6,21,12S20.1,16.7,18.4,18.4z" />
		      
		<path d="M12,5.5c0.7,0,1.3,0.6,1.3,1.3S12.7,8,12,8s-1.3-0.6-1.3-1.3S11.3,5.5,12,5.5z" />
		      
		<path d="M15,17c0,0.5-0.5,1-1,1h-3.5c-0.5,0-1-0.5-1-1s0.5-1,1-1h0.8v-5c-0.5,0-1-0.5-1-1s0.5-1,1-1h1c0.5,0,1,0.5,1,1 v6H14C14.5,16,15,16.5,15,17z" />
		    
	</symbol>
	    
	<symbol id="icon-close" viewBox="0 0 20 20">
		      
		<path d="M.293 18.291a1 1 0 101.414 1.414L10 11.413l8.292 8.292a1 1 0 101.414-1.414l-8.292-8.292 8.292-8.292A1 1 0 1018.291.293L9.999 8.585 1.707.293A1 1 0 10.293 1.707L8.585 10 .293 18.291z" />
		    
	</symbol>
	    
	<symbol id="icon_bage" viewBox="0 0 29 36">
		      
		<path d="M12.7431 2.46528C13.5358 1.28755 15.2689 1.28755 16.0615 2.46529L16.6635 3.35971C17.178 4.12421 18.1483 4.43818 19.0132 4.12004L20.0442 3.74077C21.3857 3.24728 22.7969 4.27965 22.7327 5.70762L22.6887 6.68584C22.6467 7.62088 23.2587 8.46001 24.1619 8.70559L25.1384 8.97109C26.5328 9.35025 27.0773 11.0377 26.1673 12.1604L25.5767 12.889C24.9817 13.6232 24.9817 14.6736 25.5767 15.4077L26.1673 16.1364C27.0773 17.259 26.5328 18.9465 25.1384 19.3257L24.1619 19.5912C23.2587 19.8368 22.6467 20.6759 22.6887 21.6109L22.7327 22.5892C22.7969 24.0171 21.3857 25.0495 20.0442 24.556L19.0132 24.1768C18.1483 23.8586 17.178 24.1726 16.6635 24.9371L16.0615 25.8315C15.2689 27.0092 13.5358 27.0092 12.7431 25.8315L12.1411 24.9371C11.6266 24.1726 10.6563 23.8586 9.79145 24.1768L8.76042 24.556C7.4189 25.0495 6.00777 24.0171 6.07196 22.5892L6.11594 21.6109C6.15798 20.6759 5.5459 19.8368 4.64271 19.5912L3.66626 19.3257C2.27179 18.9465 1.72736 17.259 2.63731 16.1364L3.22792 15.4077C3.82297 14.6736 3.82297 13.6232 3.22792 12.889L2.63731 12.1604C1.72736 11.0377 2.27179 9.35025 3.66626 8.97109L4.64272 8.70559C5.5459 8.46001 6.15798 7.62087 6.11594 6.68584L6.07196 5.70762C6.00777 4.27965 7.4189 3.24728 8.76042 3.74077L9.79145 4.12004C10.6563 4.43818 11.6266 4.1242 12.1411 3.3597L12.7431 2.46528Z" stroke="#FF6D03" strokeWidth={2} />
		      
		<ellipse
			cx={14.4025}
			cy={14.1484}
			rx={6.54651}
			ry={6.43109}
			stroke="#FF6D03"
			strokeWidth={2}
		/>
		      
		<path d="M4.74756 20.5795L1.1134 29.7559C0.758691 30.6515 1.7564 31.4678 2.57775 30.9539L5.35881 29.214C5.92853 28.8575 6.67879 29.1419 6.85703 29.7819L7.7813 33.1004C8.03345 34.0058 9.29709 34.0806 9.67177 33.2124L13 25.5" stroke="#FF6D03" strokeWidth={2} />
		      
		<path d="M24.2529 20.5795L27.8872 29.756C28.2419 30.6516 27.2442 31.4679 26.4228 30.9541L23.6412 29.2139C23.0715 28.8575 22.3212 29.142 22.143 29.782L21.219 33.1003C20.9669 34.0057 19.7033 34.0806 19.3286 33.2123L16 25.5" stroke="#FF6D03" strokeWidth={2} />
		    
	</symbol>
	    
	<symbol id="icon_cup" viewBox="0 0 35 34">
		      
		<path d="M8.77783 29.9364L25.3704 29.9364" stroke="#FF6D03" strokeWidth={2} />
		      
		<path d="M17.0002 20.5L17.839 25.5328C17.9407 26.1429 18.319 26.6716 18.8636 26.9649L24.5002 30" stroke="#FF6D03" strokeWidth={2} />
		      
		<path d="M17.0002 21L16.1724 25.5531C16.0636 26.1515 15.6884 26.6679 15.1529 26.9563L9.50024 30" stroke="#FF6D03" strokeWidth={2} />
		      
		<path d="M17.0743 20.3651C21.88 20.3651 25.8374 13.3295 26.3511 4.28561C26.4138 3.18282 25.5121 2.28577 24.4076 2.28577H9.74096C8.63639 2.28577 7.73477 3.18282 7.79742 4.28561C8.31118 13.3295 12.2686 20.3651 17.0743 20.3651Z" stroke="#FF6D03" strokeWidth={2} />
		      
		<path d="M26.3546 6.08915C29.304 6.83113 31.2488 9.43275 30.6985 11.9C30.1482 14.3673 27.3112 15.7659 24.3618 15.0239" stroke="#FF6D03" strokeWidth={2} />
		      
		<path d="M8.34021 6.08915C5.39083 6.83113 3.44599 9.43275 3.99629 11.9C4.54658 14.3673 7.38363 15.7659 10.333 15.0239" stroke="#FF6D03" strokeWidth={2} />
		      
		<path d="M8 6L26 6" stroke="#FF6D03" strokeWidth={2} />
		    
	</symbol>
	    
	<symbol id="icon_meditation" viewBox="0 0 33 32">
		      
		<path d="M26.4003 16C20.9333 16 16.5003 20.2987 16.5003 25.6C16.5003 20.2987 12.0673 16 6.60034 16C12.0673 16 16.5003 11.7014 16.5003 6.40002C16.5003 11.7014 20.9333 16 26.4003 16Z" stroke="#FF6D03" strokeWidth={2} strokeMiterlimit={10} />
		      
		<path d="M20.7473 8.86663L22.8057 5.4093" stroke="#FF6D03" strokeWidth={2} strokeMiterlimit={10} />
		      
		<path d="M9.1435 11.8814L5.57812 9.88538" stroke="#FF6D03" strokeWidth={2} strokeMiterlimit={10} />
		      
		<path d="M23.8567 20.1187L27.4221 22.1147" stroke="#FF6D03" strokeWidth={2} strokeMiterlimit={10} />
		      
		<path d="M10.1941 26.5906L12.2525 23.1333" stroke="#FF6D03" strokeWidth={2} strokeMiterlimit={10} />
		      
		<path d="M16.5 4.26667V0" stroke="#FF6D03" strokeWidth={2} strokeMiterlimit={10} />
		      
		<path d="M28.6001 16H33.0001" stroke="#FF6D03" strokeWidth={2} strokeMiterlimit={10} />
		      
		<path d="M4.4 16H0" stroke="#FF6D03" strokeWidth={2} strokeMiterlimit={10} />
		      
		<path d="M16.5 27.7333V31.9999" stroke="#FF6D03" strokeWidth={2} strokeMiterlimit={10} />
		      
		<path d="M23.8567 11.8814L27.4221 9.88538" stroke="#FF6D03" strokeWidth={2} strokeMiterlimit={10} />
		      
		<path d="M12.2525 8.86663L10.1941 5.4093" stroke="#FF6D03" strokeWidth={2} strokeMiterlimit={10} />
		      
		<path d="M20.7473 23.1333L22.8057 26.5906" stroke="#FF6D03" strokeWidth={2} strokeMiterlimit={10} />
		      
		<path d="M5.57812 22.1147L9.1435 20.1187" stroke="#FF6D03" strokeWidth={2} strokeMiterlimit={10} />
		    
	</symbol>
	    
	<symbol id="icon_message" viewBox="0 0 33 32">
		      
		<path d="M1 3C1 1.89543 1.89543 1 3 1H30C31.1046 1 32 1.89543 32 3V22.0159C32 23.1204 31.1046 24.0159 30 24.0159H15.6865C15.2111 24.0159 14.7513 24.1852 14.3894 24.4936L7.81524 30.0953C7.16598 30.6485 6.16667 30.1871 6.16667 29.3341V26.0159C6.16667 24.9113 5.27124 24.0159 4.16667 24.0159H3C1.89543 24.0159 1 23.1204 1 22.0159V3Z" stroke="#FF6D03" strokeWidth={2} />
		      
		<path d="M15.3488 8.67157C15.4862 7.72653 15.9953 6.92941 16.573 6.18333C17.1005 5.50193 17.696 4.80277 18.487 4.40327C19.5581 3.86227 20.8038 3.50635 22.4221 4.48339C24.2738 6.10119 24.5895 9.55628 23.6723 12.0026M12.54 15.1934C12.3004 16.4127 12.0448 17.9537 12.6952 19.1018C13.2997 20.1689 14.6267 21.0521 15.7388 21.2009C17.0401 21.3751 18.0924 20.4797 19.2521 19.8407C20.1259 19.3592 20.8881 18.4676 21.0927 17.4888C21.2849 16.5696 20.6087 15.9611 19.8453 15.5551C18.3693 14.7703 16.7583 14.2705 15.7554 12.8523C14.5831 11.1947 15.2064 8.23392 17.0615 7.27643C19.3685 6.08568 21.9874 8.08439 23.4995 9.66998C24.4171 10.6322 25.2616 11.8235 25.2355 13.1972C25.2168 14.1825 24.2751 14.1251 23.5144 13.9484C22.1294 13.6267 21.0864 12.6154 20.0186 11.7561C19.3438 11.2131 18.0311 11.184 17.3517 10.6465C16.7869 10.1995 16.7845 9.25959 16.309 8.71539C16.158 8.54259 16.1666 8.42096 16.4158 8.50643C16.816 8.64365 17.0952 8.97759 17.3388 9.2997C17.51 9.52607 15.2623 11.7247 12.7152 11.0947C11.584 10.9433 6.02724 9.43423 4.92613 9.15724M10.6047 13.7837C11.4313 14.6389 14.2426 16.3323 15.4545 16.106C16.3744 15.9342 19.6982 11.6374 20.4547 10.4951M27.785 9.23172C27.8746 14.4473 27.2363 16.0389 25.1086 18.1429C24.4892 18.7553 21.3133 21.3507 20.3044 20.0032C19.8728 19.4267 19.3184 18.521 18.9491 17.9029C18.4761 17.111 18.158 16.8458 17.6589 16.0709C17.061 15.1424 16.5509 13.9943 15.2013 14.1638C14.5654 14.2437 14.0381 14.9248 13.7183 15.4049C13.0967 16.338 12.6594 17.3838 12.0831 18.3424C11.5315 19.26 10.1485 21.0426 8.87949 20.0653C7.45633 18.9693 7.34249 16.7245 7.54628 15.1486C7.71758 13.8239 8.1128 12.5858 8.52023 11.3182C8.99522 9.84039 9.47141 8.35844 10.0141 6.90295C10.4805 5.65196 11.5451 4.40803 13.0577 4.43111C14.5347 4.45365 15.5239 5.96665 16.2191 7.05977" stroke="#FF6D03" strokeWidth={2} strokeLinecap="round" />
		    
	</symbol>
	    
	<symbol id="icon_pentagons" viewBox="0 0 34 34">
		      
		<path d="M16.9007 1.69772C17.256 1.43179 17.744 1.43179 18.0993 1.69772L31.4577 11.6973C31.7978 11.9519 31.9399 12.3935 31.8122 12.7986L26.6858 29.0541C26.5545 29.4703 26.1685 29.7533 25.7321 29.7533H9.26791C8.83149 29.7533 8.44547 29.4703 8.31421 29.0541L3.18785 12.7986C3.06009 12.3935 3.20222 11.9519 3.54229 11.6973L16.9007 1.69772Z" stroke="#FF6D03" strokeWidth={2} />
		      
		<path d="M18.4122 5.66312C18.7627 5.40848 19.2373 5.40848 19.5878 5.66312L27.6983 11.5557C28.0488 11.8104 28.1954 12.2617 28.0615 12.6738L24.9636 22.2082C24.8297 22.6202 24.4458 22.8992 24.0126 22.8992H13.9874C13.5542 22.8992 13.1703 22.6202 13.0364 22.2082L9.93846 12.6738C9.80459 12.2617 9.95125 11.8104 10.3017 11.5557L18.4122 5.66312Z" stroke="#FF6D03" strokeWidth={2} />
		    
	</symbol>
	    
	<symbol id="logo-coach" viewBox="0 0 284 812">
		      
		<path d="M-1.05415e-06 790.884L-5.27073e-06 694.42L23.627 670.304L94.5082 670.304L94.5082 718.536L47.2541 718.536L47.2541 766.768L236.271 766.768L236.271 718.536L189.016 718.536L189.016 670.304L259.898 670.304L283.525 694.42L283.525 790.884L259.898 815L23.6271 815L-1.05415e-06 790.884Z" fill="white" />
		      
		<path fillRule="evenodd" clipRule="evenodd" d="M-1.26497e-05 525.608L-8.43316e-06 622.072L23.627 646.188L259.898 646.188L283.525 622.072L283.525 525.608L259.898 501.491L23.627 501.491L-1.26497e-05 525.608ZM47.2541 549.724L47.2541 597.956L236.271 597.956L236.271 549.724L47.2541 549.724Z" fill="white" />
		      
		<path d="M-2.30594e-05 287.462L-2.7276e-05 190.997L23.627 166.881L94.5082 166.881L94.5082 215.114L47.2541 215.114L47.2541 263.346L236.271 263.346L236.271 215.114L189.016 215.114L189.016 166.881L259.898 166.881L283.525 190.997L283.525 287.462L259.898 311.578L23.627 311.578L-2.30594e-05 287.462Z" fill="white" />
		      
		<path d="M-2.93843e-05 142.765L-3.14926e-05 94.5333L118.135 94.5333L118.135 46.3013L-3.36009e-05 46.3013L-3.57092e-05 -1.93085L283.525 -1.93086L283.525 46.3013L165.389 46.3013L165.389 94.5333L283.525 94.5333L283.525 142.765L-2.93843e-05 142.765Z" fill="white" />
		      
		<path fillRule="evenodd" clipRule="evenodd" d="M283.525 440.691L236.271 433.657L236.271 379.412L283.525 372.378L283.525 323.636L-1.96335e-05 365.839L-1.50216e-05 471.346L20.62 450.3L283.525 489.433L283.525 440.691ZM54.0569 406.535L189.016 386.446L189.016 426.624L54.0569 406.535Z" fill="white" />
		    
	</symbol>
	    
	<symbol id="logo-text" viewBox="0 0 160 80">
		      
		<path fillRule="evenodd" clipRule="evenodd" d="M9.42801 23.9262L5 28.3542V72.6347L9.42801 77.0627L5 77.0627H31.5683V59.3506H22.7122V41.6384H31.5683L31.5683 28.3543L27.1402 23.9262H9.42801ZM31.5683 77.0627H27.1402L31.5683 72.6347V77.0627ZM22.7122 32.7823H13.8561V68.2066H22.7122V32.7823Z" />
		      
		<path fillRule="evenodd" clipRule="evenodd" d="M40.4243 23.9262L35.9963 28.3542V72.6347L40.4243 77.0627L35.9963 77.0627H62.5646V23.9262L62.5646 23.9262V28.3543L58.1365 23.9262H40.4243ZM62.5646 77.0627H58.1365L62.5646 72.6347V77.0627ZM53.7085 32.7823H44.8524V68.2066H53.7085V32.7823Z" />
		      
		<path fillRule="evenodd" clipRule="evenodd" d="M101.864 23.9262L97.4356 28.3542L97.4355 72.6347L101.864 77.0627L97.4356 77.0627H124.004L119.576 77.0627L124.004 72.6347V28.3542L119.576 23.9262H101.864ZM115.148 41.6384H124.004V59.3506H115.148V41.6384ZM115.148 32.7823H106.292V68.2066H115.148V32.7823Z" />
		      
		<path fillRule="evenodd" clipRule="evenodd" d="M146.144 23.9262V46.0664H137.288V23.9262L128.432 23.9262V77.0627H155V23.9262L146.144 23.9262ZM146.144 54.9225H137.288V77.0627H146.144V54.9225Z" />
		      
		<path fillRule="evenodd" clipRule="evenodd" d="M68.0996 23.9262H72.5277L87.4723 23.9262L95.2214 77.0627H86.2716L84.9801 68.2067H75.0198L73.7283 77.0627H64.7786L71.9641 27.7907L68.0996 23.9262ZM83.6886 59.3506H76.3114L80 34.0572L83.6886 59.3506Z" />
		      
		<path fillRule="evenodd" clipRule="evenodd" d="M71.6973 4H69.4832V4.00001H67.2693V6.21403H69.4832V15.0701H71.6973V6.21403H73.9114V4.00001H71.6973V4ZM82.2139 8.42805H82.2139V10.6421H82.2139V15.0701H79.9999V10.6421H77.7859V15.0701H75.5719V10.6421V8.42805V4H77.7859V8.42805H79.9999V4H82.2139V8.42805ZM86.6419 4H91.07V6.21402H86.6419V8.42805H89.9629V10.6421H86.6419V12.8561H91.07V15.0701H84.4279V15.0701V12.8561V10.6421V8.42805V6.21402V4H86.6419Z" fill="#FF6D03" />
		    
	</symbol>
	    
	<symbol id="logo-white" viewBox="0 0 40 40">
		      
		<rect width={40} height={40} rx={8} fill="white" />
		      
		<path d="M5.21356 14.9258H8.92467L9.85244 15.8347V18.5616H7.99689V16.7437H6.14134V24.0154H7.99689V22.1975H9.85244V24.9244L8.92467 25.8334H5.21356L4.28579 24.9244V15.8347L5.21356 14.9258Z" fill="#1D4034" />
		      
		<path fillRule="evenodd" clipRule="evenodd" d="M15.4191 14.9258H11.708L10.7802 15.8347V24.9244L11.708 25.8334H15.4191L16.3469 24.9244V15.8347L15.4191 14.9258ZM14.4913 16.7437H12.6358V24.0154H14.4913V16.7437Z" fill="#1D4034" />
		      
		<path d="M24.5809 14.9258H28.292L29.2198 15.8347V18.5616H27.3642V16.7437H25.5087V24.0154H27.3642V22.1975H29.2198V24.9244L28.292 25.8334H24.5809L23.6531 24.9244V15.8347L24.5809 14.9258Z" fill="#1D4034" />
		      
		<path d="M30.1476 14.9258H32.0031V19.4706H33.8587V14.9258H35.7142V25.8334H33.8587V21.2885H32.0031V25.8334H30.1476V14.9258Z" fill="#1D4034" />
		      
		<path fillRule="evenodd" clipRule="evenodd" d="M18.6859 25.8334L18.9565 24.0154H21.0434L21.314 25.8334H23.1892L21.5656 14.9258H17.5066L18.3163 15.7191L16.8108 25.8334H18.6859ZM20 17.0054L20.7728 22.1975H19.2271L20 17.0054Z" fill="#1D4034" />
		      
		<path d="M18.5271 10V10.6667H17.907V13.3333H17.2868V10.6667H16.6667V10H18.5271Z" fill="#F8BDA3" />
		      
		<path d="M19.6124 11.3333H20.2326V10H20.8527V13.3333H20.2326V12H19.6124V13.3333H18.9922V10H19.6124V11.3333Z" fill="#F8BDA3" />
		      
		<path d="M23.3333 10V10.6667H22.093V11.3333H23.0233V12H22.093V12.6667H23.3333V13.3333H21.4729V10H23.3333Z" fill="#F8BDA3" />
		    
	</symbol>
	    
	<symbol id="logo" viewBox="0 0 60 60">
		      
		<rect width={60} height={60} rx={12} fill="#1D4034" />
		      
		<path d="M9.29889 22.7294H14.4945L15.7934 24.0019V27.8196H13.1956V25.2745H10.5978V35.4549H13.1956V32.9098H15.7934V36.7275L14.4945 38H9.29889L8 36.7275V24.0019L9.29889 22.7294Z" fill="white" />
		      
		<path fillRule="evenodd" clipRule="evenodd" d="M23.5867 22.7294H18.3911L17.0923 24.0019V36.7275L18.3911 38H23.5867L24.8856 36.7275V24.0019L23.5867 22.7294ZM22.2878 25.2745H19.69V35.4549H22.2878V25.2745Z" fill="white" />
		      
		<path d="M36.4133 22.7294H41.6089L42.9077 24.0019V27.8196H40.31V25.2745H37.7122V35.4549H40.31V32.9098H42.9077V36.7275L41.6089 38H36.4133L35.1144 36.7275V24.0019L36.4133 22.7294Z" fill="white" />
		      
		<path d="M44.2066 22.7294H46.8044V29.0922H49.4022V22.7294H52V38H49.4022V31.6373H46.8044V38H44.2066V22.7294Z" fill="white" />
		      
		<path fillRule="evenodd" clipRule="evenodd" d="M28.1603 38L28.5392 35.4549H31.4608L31.8397 38H34.4649L32.1919 22.7294H26.5092L27.6428 23.84L25.5351 38H28.1603ZM30 25.6409L31.082 32.9098H28.918L30 25.6409Z" fill="white" />
		      
		<path d="M28.221 17V17.6363H27.5716V20.1814H26.9222V17.6363H26.2727V17H28.221Z" fill="#F8BDA3" />
		      
		<path d="M29.3576 18.2726H30.007V17H30.6565V20.1814H30.007V18.9088H29.3576V20.1814H28.7081V17H29.3576V18.2726Z" fill="#F8BDA3" />
		      
		<path d="M33.2542 17V17.6363H31.9554V18.2726H32.9295V18.9088H31.9554V19.5451H33.2542V20.1814H31.3059V17H33.2542Z" fill="#F8BDA3" />
		    
	</symbol>
	    
	<symbol id="open" viewBox="0 0 25 19">
		      
		<path d="M23.5,11h-22C0.7,11,0,10.3,0,9.5S0.7,8,1.5,8h22C24.3,8,25,8.7,25,9.5S24.3,11,23.5,11z" />
		      
		<path d="M23.5,3h-22C0.7,3,0,2.3,0,1.5S0.7,0,1.5,0h22C24.3,0,25,0.7,25,1.5S24.3,3,23.5,3z" />
		      
		<path d="M23.5,19h-22C0.7,19,0,18.3,0,17.5S0.7,16,1.5,16h22c0.8,0,1.5,0.7,1.5,1.5S24.3,19,23.5,19z" />
		    
	</symbol>
	    
	<symbol id="qoute-open" viewBox="0 0 64 44">
		      
		<path d="M26.8594 0.992188C12.7266 2.96094 0 12.875 0 27.3594C0 37.3438 6.04688 43.9531 14.625 43.9531C23.7656 43.9531 29.1797 36.4297 29.1797 26.8672C21.9375 26.5859 17.0859 22.2969 17.0859 15.8984C17.0859 9.78125 21.6562 5.91406 28.0547 4.78906L26.8594 0.992188ZM61.1016 0.992188C46.9688 2.96094 34.2422 12.875 34.2422 27.3594C34.2422 37.3438 40.2891 43.9531 48.8672 43.9531C58.0078 43.9531 63.4219 36.4297 63.4219 26.8672C56.1797 26.5859 51.3281 22.2969 51.3281 15.8984C51.3281 9.78125 55.8984 5.91406 62.2969 4.78906L61.1016 0.992188Z" />
		    
	</symbol>
	    
	<symbol id="shield-check" viewBox="0 0 18 19">
		      
		<path fillRule="evenodd" clipRule="evenodd" d="M2.25 4.54883L9 1.54883L15.75 4.54883V9.04883C15.75 13.2113 12.87 17.1038 9 18.0488C5.13 17.1038 2.25 13.2113 2.25 9.04883V4.54883ZM12.8981 8.00898C13.1178 7.78931 13.1178 7.43315 12.8981 7.21348C12.6785 6.99381 12.3223 6.99381 12.1026 7.21348L8.12539 11.1907L6.33564 9.40098C6.11597 9.18131 5.75981 9.18131 5.54014 9.40098C5.32047 9.62065 5.32047 9.97681 5.54014 10.1965L7.72764 12.384C7.94731 12.6036 8.30347 12.6036 8.52314 12.384L12.8981 8.00898Z" />
		    
	</symbol>
	    
	<symbol id="star" viewBox="0 0 16 15">
		      
		<path d="M15.9395 5.65416C15.8682 5.43638 15.731 5.24456 15.5459 5.10374C15.3607 4.96291 15.1362 4.87962 14.9017 4.86472L10.6599 4.57607L9.08639 0.723252C9.00124 0.510249 8.8519 0.327228 8.65794 0.198171C8.46399 0.0691129 8.23446 3.89333e-05 7.99944 0H7.99833C7.76367 0.000406982 7.5346 0.0696571 7.34107 0.198695C7.14754 0.327733 6.99856 0.510554 6.91361 0.723252L5.31666 4.59831L1.09831 4.86472C0.863754 4.87962 0.639263 4.96291 0.454125 5.10374C0.268987 5.24456 0.131799 5.43638 0.0604606 5.65416C-0.014797 5.87504 -0.0199166 6.11283 0.0457685 6.33657C0.111454 6.56031 0.2449 6.75964 0.428729 6.90859L3.67396 9.57589L2.70865 13.2681C2.64211 13.5168 2.65435 13.7793 2.74375 14.0211C2.83316 14.2629 2.99556 14.4729 3.20972 14.6235C3.41647 14.7697 3.66372 14.8521 3.91929 14.8598C4.17485 14.8676 4.42689 14.8003 4.64261 14.6669L7.99051 12.6045C7.99498 12.6013 7.99944 12.5991 8.00948 12.6045L11.6118 14.8237C11.8074 14.9452 12.0361 15.0064 12.2681 14.9995C12.5 14.9925 12.7244 14.9176 12.9119 14.7846C13.106 14.6482 13.2531 14.458 13.3342 14.2389C13.4152 14.0199 13.4264 13.7821 13.3661 13.5568L12.3405 9.52326L15.5713 6.90859C15.7551 6.75964 15.8885 6.56031 15.9542 6.33657C16.0199 6.11283 16.0148 5.87504 15.9395 5.65416Z" />
		    
	</symbol>
	    
	<symbol id="time-stopwatch-3-quarters" viewBox="0 0 28 28">
		      
		<path fillRule="evenodd" clipRule="evenodd" d="M22.7815 9.36208C22.6775 9.46176 22.667 9.62015 22.757 9.73168C25.8396 13.5501 25.8603 18.8818 22.8073 22.7222C19.7544 26.5626 14.392 27.9504 9.74196 26.1037C5.09191 24.257 2.31806 19.6379 2.98353 14.8494C3.64901 10.0609 7.58725 6.30142 12.579 5.6896C12.7247 5.67205 12.8339 5.55284 12.8333 5.41184V2.52C12.8333 2.36536 12.7027 2.24 12.5416 2.24H10.5C9.85564 2.24 9.33331 1.73856 9.33331 1.12C9.33331 0.501441 9.85564 0 10.5 0H17.5C18.1443 0 18.6666 0.501441 18.6666 1.12C18.6666 1.73856 18.1443 2.24 17.5 2.24H15.4583C15.3808 2.24 15.3064 2.26965 15.2517 2.32241C15.1969 2.37516 15.1663 2.44667 15.1666 2.52112V5.41184C15.1666 5.55265 15.2755 5.67158 15.421 5.6896C17.3589 5.93025 19.1954 6.66118 20.7421 7.80752C20.8581 7.89325 21.0223 7.88319 21.126 7.784L23.058 5.92928C23.3509 5.63813 23.7847 5.52136 24.1926 5.62386C24.6005 5.72636 24.919 6.03217 25.0258 6.42375C25.1326 6.81534 25.0109 7.23176 24.7076 7.51296L22.7815 9.36208ZM5.24998 16.24C5.24998 20.8792 9.16748 24.64 14 24.64C18.8303 24.6351 22.7448 20.8771 22.75 16.24C22.75 11.6008 18.8325 7.84 14 7.84C9.16748 7.84 5.24998 11.6008 5.24998 16.24Z" fill="white" />
		      
		<path d="M14 9.51953C14.3222 9.51953 14.5833 9.77025 14.5833 10.0795V15.6795H20.4167C20.7388 15.6795 21 15.9303 21 16.2395C21 19.9509 17.866 22.9595 14 22.9595C10.134 22.9595 7 19.9509 7 16.2395C7 12.5282 10.134 9.51953 14 9.51953Z" fill="white" />
		    
	</symbol>
	    
	<symbol id="wreath-l" viewBox="0 0 23.972973 40.55896">
		      
		<g clipPath="url(#clip0_2705_8428)" id="g36" transform="translate(-4.192927,-2.68224)">
			        
			<path d="m 20.3832,40.0052 c -0.312,-0.2508 -0.624,-0.5015 -0.9584,-0.6546 -0.3345,-0.153 -0.6689,-0.306 -1.0258,-0.3613 -0.7138,-0.1107 -1.688,-0.1605 -2.4467,-0.0756 -0.8776,0.0663 -1.6363,0.1512 -2.2536,0.1567 -0.6397,0.1032 -1.019,0.1457 -1.019,0.1457 0,0 0.312,0.2507 0.8619,0.5383 0.5499,0.2877 1.3378,0.6122 2.1257,0.9367 0.8103,0.2268 1.762,0.3743 2.6397,0.3079 0.8776,-0.0664 1.7036,-0.4444 2.0762,-0.9938 z" id="path2" style={{
				fill: '#6f6342'
			}} />
			        
			<path d="m 18.6777,36.7039 c 0.2222,0.6417 0.4445,1.2833 0.972,1.6687 0.193,0.2323 0.5274,0.3853 0.8619,0.5384 0.3344,0.153 0.7138,0.1106 1.0931,0.0682 0.1571,-0.6841 0.0539,-1.3073 -0.1908,-1.8512 -0.2446,-0.5439 -0.6307,-1.0085 -1.1358,-1.4916 -0.386,-0.4647 -0.9135,-0.85 -1.2255,-1.1008 -0.312,-0.2507 -0.6465,-0.4038 -0.6465,-0.4038 0,0 -0.0224,0.0978 -0.0449,0.1955 -0.0224,0.0977 -0.0673,0.2931 0.0068,0.507 -0.0157,0.6048 0.0875,1.228 0.3097,1.8696 z" id="path4" style={{
				fill: '#6f6342'
			}} />
			        
			<path d="m 12.512,37.2657 c 0.8327,0.129 1.6139,-0.0535 2.2244,-0.5661 C 14.5142,36.058 13.9867,35.6726 13.1988,35.3481 12.5299,35.042 11.6972,34.913 10.8869,34.6862 10.0541,34.5571 9.22136,34.4281 8.62654,34.3359 8.03171,34.2437 7.55585,34.1699 7.55585,34.1699 c 0,0 0.09652,0.1162 0.19304,0.2323 0.09651,0.1162 0.28955,0.3485 0.48259,0.5808 0.38608,0.4647 1.12905,0.9846 1.89442,1.4069 0.743,0.5199 1.5309,0.8444 2.3861,0.8758 z" id="path6" style={{
				fill: '#6f6342'
			}} />
			        
			<path d="m 14.6419,34.9644 c 0.3637,0.5623 0.8911,0.9477 1.7239,1.0767 0.514,-0.6287 0.5297,-1.2335 0.404,-1.759 -0.1032,-0.6232 -0.3479,-1.1671 -0.615,-1.6133 -0.2447,-0.5439 -0.6532,-0.9108 -0.8238,-1.2409 -0.0965,-0.1161 -0.1706,-0.33 -0.2895,-0.3484 -0.0965,-0.1162 -0.0965,-0.1162 -0.0965,-0.1162 0,0 -0.0225,0.0977 -0.1639,0.177 -0.0225,0.0977 -0.1863,0.2747 -0.2312,0.4702 -0.2088,0.3724 -0.3659,1.0565 -0.3816,1.6612 0.0292,0.4094 0.11,1.1303 0.4736,1.6927 z" id="path8" style={{
				fill: '#6f6342'
			}} />
			        
			<path d="M 5.86746,28.6481 C 5.29508,28.4582 4.96063,28.3052 4.96063,28.3052 c 0,0 -0.02244,0.0977 0.07407,0.2139 0.09652,0.1161 0.1706,0.33 0.24467,0.5439 0.07407,0.2139 0.24467,0.5439 0.4377,0.7762 -0.04489,0.1955 0.05163,0.3116 0.1257,0.5255 l 0.09652,0.1162 0.09652,0.1161 c 0.09652,0.1162 0.07407,0.2139 0.17059,0.3301 0.55667,0.7946 1.2772,1.4123 1.9461,1.7184 0.78786,0.3245 1.5241,0.3374 2.3277,0.0571 C 10.3994,31.9817 10.1323,31.5355 9.50825,31.034 9.43418,30.8201 9.33766,30.704 9.2187,30.6855 9.12218,30.5694 8.90669,30.4348 8.81017,30.3186 8.59469,30.184 8.49817,30.0678 8.28268,29.9333 8.0672,29.7987 7.97068,29.6825 7.73275,29.6456 7.0863,29.2418 6.29843,28.9173 5.86746,28.6481 Z" id="path10" style={{
				fill: '#6f6342'
			}} />
			        
			<path d="m 11.1892,30.6862 c 0.1033,0.6232 0.4669,1.1855 1.1583,1.3939 0.5881,-0.4149 0.9607,-0.9643 1.0954,-1.5507 0.1346,-0.5863 0.2469,-1.0749 0.2401,-1.5819 0.0449,-0.1955 -0.0067,-0.5071 -0.0583,-0.8187 -0.0517,-0.3116 -0.1257,-0.5255 -0.0808,-0.7209 -0.0741,-0.2139 -0.0292,-0.4093 -0.1257,-0.5255 0.0224,-0.0977 -0.0741,-0.2139 -0.0741,-0.2139 0,0 -0.0225,0.0977 -0.1639,0.177 -0.1414,0.0793 -0.3052,0.2563 -0.4691,0.4333 -0.3277,0.354 -0.8193,0.885 -1.0729,1.4529 -0.0449,0.1955 -0.1863,0.2748 -0.2312,0.4702 -0.0449,0.1955 -0.1863,0.2747 -0.2312,0.4702 -0.0449,0.1954 -0.0674,0.2931 -0.1123,0.4886 0.0741,0.2139 0.0292,0.4093 0.1257,0.5255 z" id="path12" style={{
				fill: '#6f6342'
			}} />
			        
			<path d="m 5.45443,26.1521 c 0.09652,0.1161 0.17059,0.33 0.38608,0.4646 0.09652,0.1162 0.312,0.2508 0.40852,0.3669 0.62401,0.5016 1.31535,0.7099 2.193,0.6435 C 8.62834,27.3524 8.57671,27.0408 8.52508,26.7292 8.54753,26.6315 8.47346,26.4176 8.37694,26.3014 8.28042,26.1853 8.20635,25.9714 8.10983,25.8552 7.7462,25.2929 7.24115,24.8098 6.61715,24.3083 6.1121,23.8252 5.46565,23.4214 4.93816,23.0361 c -0.54993,-0.2877 -0.74297,-0.52 -0.74297,-0.52 0,0 -0.02245,0.0977 0.07407,0.2139 -0.02244,0.0977 0.02918,0.4093 0.10326,0.6232 0.1257,0.5255 0.2065,1.2464 0.54769,1.9065 0.17059,0.33 0.34118,0.6601 0.53422,0.8924 z" id="path14" style={{
				fill: '#6f6342'
			}} />
			        
			<path d="m 9.90054,26.6409 c 0.05162,0.3116 0.24466,0.5439 0.55666,0.7946 0.8036,-0.2802 1.3917,-0.6951 1.7418,-1.1468 0.0225,-0.0977 0.1863,-0.2747 0.2088,-0.3724 0.0224,-0.0978 0.0224,-0.0978 0.0449,-0.1955 0.0224,-0.0977 0.0449,-0.1954 0.0449,-0.1954 0.0673,-0.2932 0.1346,-0.5864 0.202,-0.8795 0.1347,-0.5864 0.1279,-1.0934 0.2177,-1.4843 0.0449,-0.1954 -0.0292,-0.4093 -0.0067,-0.507 0.0224,-0.0978 0.0449,-0.1955 0.0449,-0.1955 0,0 -0.1414,0.0793 -0.2604,0.0609 -0.1414,0.0793 -0.2828,0.1585 -0.5657,0.3171 -0.4466,0.3356 -0.9158,0.7689 -1.3849,1.2022 -0.4916,0.531 -0.86417,1.0804 -0.90233,1.7829 -0.04489,0.1955 -0.06733,0.2932 0.02918,0.4093 -0.02244,0.0978 -0.04489,0.1955 0.02919,0.4094 z" id="path16" style={{
				fill: '#6f6342'
			}} />
			        
			<path d="m 5.38027,20.5776 c -0.02245,0.0977 0.05163,0.3116 0.14814,0.4278 0.09652,0.1161 0.1706,0.33 0.26712,0.4462 0.48259,0.5808 1.15149,0.8868 1.98425,1.0159 C 7.96608,22.1928 8.15239,21.918 8.10076,21.6064 8.12321,21.5087 8.04913,21.2948 8.07158,21.1971 8.09403,21.0994 8.01995,20.8855 7.92343,20.7694 8.01322,20.3785 7.84263,20.0484 7.67203,19.7184 7.57551,19.6022 7.50144,19.3883 7.40492,19.2722 7.3084,19.156 7.23433,18.9422 7.13781,18.826 6.65522,18.2452 6.17262,17.6644 5.88306,17.3159 5.47454,16.949 5.16254,16.6982 5.16254,16.6982 c 0,0 -0.08979,0.3909 -0.20202,0.8795 -0.06734,0.2932 -0.01571,0.6048 -0.08305,0.898 -0.04489,0.1954 0.05163,0.3116 0.00673,0.507 -0.04489,0.1955 0.05163,0.3116 0.00674,0.5071 0.17059,0.33 0.34118,0.66 0.48933,1.0878 z" id="path18" style={{
				fill: '#6f6342'
			}} />
			        
			<path d="m 9.89199,22.3895 c 0.87761,-0.0664 1.46571,-0.4813 1.93491,-0.9146 0.1414,-0.0793 0.1863,-0.2747 0.3277,-0.354 0.1414,-0.0793 0.1863,-0.2747 0.3277,-0.354 0.1863,-0.2747 0.3726,-0.5494 0.4175,-0.7449 0.1863,-0.2747 0.2536,-0.5679 0.2985,-0.7633 0.0225,-0.0977 0.0449,-0.1955 0.0898,-0.3909 0.0225,-0.0977 0.0449,-0.1955 0.0673,-0.2932 0.0898,-0.3909 0.1347,-0.5863 0.1347,-0.5863 0,0 -0.3793,0.0424 -0.8036,0.2803 -0.1414,0.0792 -0.2603,0.0608 -0.4017,0.1401 -0.1415,0.0793 -0.1639,0.177 -0.3053,0.2563 -0.2828,0.1585 -0.5657,0.3171 -0.8709,0.5734 -0.1414,0.0793 -0.3053,0.2563 -0.4467,0.3356 -0.0225,0.0977 -0.1414,0.0792 -0.1639,0.177 -0.0224,0.0977 -0.1414,0.0793 -0.1638,0.177 -0.1863,0.2747 -0.37263,0.5494 -0.55893,0.8242 -0.02245,0.0977 -0.06734,0.2931 -0.08979,0.3908 -0.02245,0.0978 -0.06734,0.2932 0.02918,0.4094 -0.1863,0.2747 0.00674,0.507 0.17733,0.8371 z" id="path20" style={{
				fill: '#6f6342'
			}} />
			        
			<path d="M 8.94456,13.6401 C 8.77397,13.3101 8.72234,12.9985 8.55175,12.6685 8.38116,12.3384 8.30709,12.1245 8.23301,11.9107 7.9659,11.4645 7.79531,11.1344 7.79531,11.1344 c 0,0 -0.20875,0.3725 -0.43995,0.8426 -0.06733,0.2932 -0.25364,0.5679 -0.32098,0.8611 -0.04489,0.1954 -0.06734,0.2932 -0.11223,0.4886 -0.04489,0.1954 -0.08978,0.3909 -0.11223,0.4886 -0.08978,0.3909 -0.03816,0.7025 -0.00898,1.1118 0.05163,0.3116 0.08081,0.7209 0.2514,1.051 0.22222,0.6416 0.84623,1.1431 1.65654,1.3699 C 8.85029,17.2687 9.01415,17.0917 9.0366,16.994 9.17801,16.9147 9.2229,16.7193 9.24535,16.6216 9.31268,16.3284 9.38002,16.0352 9.44736,15.7421 9.39574,15.4305 9.34411,15.1189 9.29248,14.8073 9.16678,14.2818 8.99619,13.9517 8.94456,13.6401 Z" id="path22" style={{
				fill: '#6f6342'
			}} />
			        
			<path d="m 12.8601,16.9785 c 0.2829,-0.1586 0.5657,-0.3172 0.752,-0.5919 0.2828,-0.1586 0.4691,-0.4333 0.633,-0.6103 0.1414,-0.0793 0.1638,-0.177 0.3277,-0.354 0.1414,-0.0793 0.1638,-0.177 0.3053,-0.2563 0.1638,-0.177 0.3277,-0.354 0.3726,-0.5495 0.2087,-0.3724 0.3726,-0.5494 0.3726,-0.5494 0,0 -0.3794,0.0424 -0.8777,0.0664 -0.5207,0.1217 -1.2794,0.2065 -1.964,0.5052 -0.1414,0.0793 -0.4018,0.1401 -0.5432,0.2194 -0.1414,0.0793 -0.2829,0.1585 -0.4243,0.2378 -0.2828,0.1586 -0.5881,0.4149 -0.7744,0.6896 -0.1863,0.2747 -0.3726,0.5495 -0.4399,0.8426 -0.0225,0.0978 -0.0673,0.2932 0.0292,0.4094 -0.0225,0.0977 0.0516,0.3116 0.1481,0.4277 0.7363,0.0129 1.3984,-0.1881 2.083,-0.4867 z" id="path24" style={{
				fill: '#6f6342'
			}} />
			        
			<path d="m 11.3714,12.1937 c 0.1414,-0.0793 0.3053,-0.2563 0.3277,-0.354 0.1414,-0.0793 0.1863,-0.2747 0.2088,-0.3725 0.0224,-0.0977 0.0673,-0.2931 0.2087,-0.3724 0.0674,-0.2932 0.1347,-0.5863 0.1055,-0.9957 C 12.1705,9.78754 12.2603,9.39665 12.2087,9.08505 12.2468,8.38256 12.1436,7.75935 12.1368,7.25231 12.0111,6.72682 11.9595,6.41522 11.9595,6.41522 c 0,0 -0.0224,0.09772 -0.1639,0.17701 -0.1414,0.07928 -0.3052,0.25628 -0.4915,0.53101 -0.3277,0.35401 -0.7228,1.00119 -1.1179,1.64836 -0.0897,0.39089 -0.27605,0.66561 -0.36583,1.0565 -0.04489,0.1954 -0.08979,0.3909 -0.11223,0.4886 -0.04489,0.1955 0.05162,0.3116 0.00673,0.5071 0.05163,0.3116 0.10326,0.6232 0.29633,0.8555 0.193,0.2323 0.505,0.4831 0.817,0.7338 0.2604,-0.0608 0.4018,-0.1401 0.5432,-0.2194 z" id="path26" style={{
				fill: '#6f6342'
			}} />
			        
			<path d="m 18.6176,10.6854 c 0.1414,-0.0793 0.1638,-0.177 0.1638,-0.177 0,0 -0.1189,-0.0184 -0.2379,-0.0369 -0.119,-0.0184 -0.3569,-0.0553 -0.5948,-0.0921 -0.238,-0.0369 -0.6173,0.0055 -0.9742,-0.0498 -0.2828,0.1585 -0.5208,0.1217 -0.6622,0.2009 -0.1189,-0.0184 -0.3793,0.0425 -0.4983,0.024 -0.4018,0.1401 -0.7811,0.1826 -1.1829,0.3227 -0.2828,0.1585 -0.6846,0.2987 -0.9674,0.4572 -0.1414,0.0793 -0.2829,0.1586 -0.3278,0.3541 -0.1414,0.0792 -0.1863,0.2747 -0.3277,0.354 -0.0224,0.0977 -0.1863,0.2747 -0.2087,0.3724 -0.0449,0.1955 -0.0674,0.2932 0.0067,0.5071 0.3345,0.153 0.8328,0.129 1.1897,0.1843 0.3793,-0.0424 0.7587,-0.0848 1.019,-0.1456 0.1414,-0.0793 0.2829,-0.1586 0.5208,-0.1217 0.119,0.0184 0.1414,-0.0793 0.2604,-0.0609 0.0449,-0.1954 0.1863,-0.2747 0.1863,-0.2747 0.2828,-0.1586 0.5656,-0.3171 0.8484,-0.4757 0.2829,-0.1586 0.5657,-0.3171 0.7295,-0.4941 l 0.1415,-0.0793 0.1414,-0.0793 c 0.1414,-0.0793 0.1638,-0.177 0.3052,-0.2563 0.1639,-0.177 0.3277,-0.354 0.4692,-0.4333 z" id="path28" style={{
				fill: '#6f6342'
			}} />
			        
			<path d="m 14.5973,8.34271 c 0.4018,-0.14013 0.6846,-0.29869 0.9675,-0.45726 0.2828,-0.15857 0.4691,-0.4333 0.6554,-0.70802 0.3726,-0.54946 0.5297,-1.23351 0.6868,-1.91756 0.0449,-0.19544 0.0674,-0.29316 0.1123,-0.48861 0.0449,-0.19544 0.0673,-0.29316 0.1122,-0.4886 0.0674,-0.29317 0.0157,-0.60477 0.0606,-0.80021 -0.0067,-0.50705 0.0606,-0.80021 0.0606,-0.80021 0,0 -0.1414,0.07928 -0.2828,0.15856 -0.1414,0.07929 -0.2828,0.15857 -0.5656,0.31714 -0.1864,0.27472 -0.4692,0.43329 -0.7744,0.68958 -0.1639,0.17701 -0.4692,0.43329 -0.6779,0.80574 -0.3053,0.25629 -0.4916,0.53102 -0.7003,0.90347 -0.1864,0.27472 -0.2761,0.66561 -0.3435,0.95877 -0.0381,0.70249 0.1841,1.34414 0.6891,1.82721 z" id="path30" style={{
				fill: '#6f6342'
			}} />
			        
			<path d="m 20.5163,8.85455 c 0.2829,-0.15857 0.5432,-0.21941 0.8261,-0.37798 0.2828,-0.15857 0.5432,-0.21941 0.707,-0.39642 0.1414,-0.07928 0.4018,-0.14013 0.4243,-0.23785 0.1414,-0.07928 0.1414,-0.07928 0.1414,-0.07928 0,0 -0.3345,-0.15304 -0.8103,-0.22679 C 21.5668,7.49935 21.2099,7.44404 20.853,7.38873 20.4961,7.33341 20.1392,7.2781 19.7599,7.32051 19.0461,7.20988 18.2874,7.29469 17.6028,7.59339 17.32,7.75196 17.0372,7.91052 16.8509,8.18525 c -0.2829,0.15857 -0.3502,0.45173 -0.4175,0.7449 0.6464,0.40379 1.4792,0.53286 2.1189,0.4296 0.3793,-0.0424 0.7587,-0.08481 1.0191,-0.14566 0.4017,-0.14013 0.6621,-0.20097 0.9449,-0.35954 z" id="path32" style={{
				fill: '#6f6342'
			}} />
			        
			<path d="m 15.9192,36.3768 c 3.0684,2.1959 7.7799,4.7478 12.2467,6.7557 l -1.257,0.1087 C 24.3006,41.7238 21.8338,40.1271 19.8495,39.1111 17.4343,37.826 14.9226,36.4247 13.1403,34.5293 11.1426,32.4993 9.94847,30.189 9.29752,27.6593 8.54333,24.5064 8.16847,21.3111 9.00571,18.2024 9.75317,15.4846 11.2144,12.8775 13.2256,10.558 14.8866,8.69023 16.7631,6.95705 18.9517,5.47464 18.9,5.16303 18.9449,4.96759 19.1312,4.69286 c 0.1639,-0.177 0.3502,-0.45173 0.6555,-0.70802 0.4691,-0.43329 1.2951,-0.81127 2.0762,-0.99381 0.7812,-0.18253 1.5399,-0.26735 2.0382,-0.29132 0.2379,0.03688 0.4983,-0.02397 0.6172,-0.00553 0.119,0.01844 0.119,0.01844 0.119,0.01844 0,0 -0.0224,0.09772 -0.1414,0.07928 -0.1414,0.07928 -0.1863,0.27473 -0.3502,0.45173 -0.3277,0.35401 -0.7968,0.78731 -1.2659,1.2206 -0.2829,0.15857 -0.5881,0.41486 -0.871,0.57342 -0.1414,0.07929 -0.2828,0.15857 -0.4242,0.23785 -0.1414,0.07929 -0.2828,0.15857 -0.4242,0.23785 -0.2829,0.15857 -0.6622,0.20098 -1.0415,0.24339 -0.238,-0.03688 -0.4983,0.02397 -0.8552,-0.03135 -0.8934,0.67115 -1.7868,1.34229 -2.5836,2.1296 -3.2166,2.73991 -5.973,6.15831 -7.08629,9.93261 -0.97866,3.1879 -0.74521,6.4625 0.1055,9.7315 0.47359,1.6927 1.06619,3.4037 2.20199,4.8953 0.9944,1.571 2.3165,2.7879 4.0179,3.9624 z" id="path34" style={{
				fill: '#6f6342'
			}} />
			      
		</g>
		    
	</symbol>
	  
</svg>;

export default atomize(SvgProvider)({
	name: 'SvgProvider',
	effects: {
		hover: ':hover'
	},
	normalize: true,
	mixins: true,
	description: {
		// paste here description for your component
		en: 'SvgProvider — my awesome component'
	},
	propInfo: {}
});