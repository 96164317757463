import React, { useRef } from 'react';
import { useOverrides } from '@quarkly/components';
import { Button } from '@quarkly/widgets';
import { useOnClickQuarklySafe } from './Utils';
import { useAnalytics } from './Analytics';
const defaultProps = {
	'border-radius': '4px',
	'text-transform': 'uppercase',
	outline: '0',
	cursor: 'pointer',
	'font-family': "'SF-Pro-Text','Arial',sans-serif",
	'letter-spacing': '.06em',
	'font-size': '14px',
	'font-weight': '700',
	'min-height': '56px',
	width: '100%',
	'border-color': '--color-darkButtonBorderColor',
	'border-width': '1px',
	'border-style': 'solid',
	background: '--darkButtonBackground',
	'focus-box-shadow': 'reset',
	'focus-opacity': '0.9',
	'hover-opacity': '0.9',
	href: '/app/4',
	type: 'link',
	'text-align': 'center',
	'text-decoration-line': 'initial',
	display: 'flex',
	'justify-content': 'center',
	'align-items': 'center',
	'flex-direction': 'column',
	children: 'GOT IT'
};
const overrides = {};

function AnswerButton({
	questionId,
	amplitude,
	...props
}) {
	const {
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	const ref = useRef();
	const analytics = useAnalytics();
	const onClick = useOnClickQuarklySafe(() => {
		const title = document.querySelector('#question-title')?.textContent || '';
		analytics({
			type: 'OnboardingSurveyQuestionAnswered',
			question_id: questionId,
			amplitude,
			answer: 'passed',
			title,
			button: ref.current?.textContent || ''
		});
	}, [analytics, questionId, amplitude]);
	return <Button ref={ref} onClick={onClick} {...rest}>
		      
		{children}
		    
	</Button>;
}

const propInfo = { ...Button.propInfo,
	questionId: {
		control: 'input',
		weight: 1,
		category: 'Analytics'
	},
	amplitude: {
		control: 'input',
		weight: 1,
		category: 'Analytics'
	}
};
Object.assign(AnswerButton, { ...Button,
	propInfo,
	defaultProps,
	overrides
});
export default AnswerButton;