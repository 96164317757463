import React from 'react';
import { useOverrides } from '@quarkly/components';
import { Button } from '@quarkly/widgets';
import SvgIcon from './SvgIcon';
const defaultProps = {
	padding: '3px 3px 3px 0',
	width: '16px',
	height: '22px',
	background: 'none',
	type: 'link',
	border: '0',
	'grid-area': 'back',
	'focus-box-shadow': 'none'
};
const overrides = {
	Icon: {
		kind: 'SvgIcon',
		props: {
			width: '100%',
			height: '100%',
			fill: 'currentColor',
			icon: 'chevron-left'
		}
	}
};

function BackButton(props) {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Button {...rest}>
		      
		<SvgIcon {...override('Icon')} />
		      
		{children}
		    
	</Button>;
}

Object.assign(BackButton, { ...Button,
	defaultProps,
	overrides
});
export default BackButton;