import React, { useCallback, createContext, useContext } from 'react';
import atomize from '@quarkly/atomize';
import { useNavigate } from './Navigate';
import { useAnalytics } from './Analytics';
const StyledForm = atomize.form();
const REDIRECT_TIMEOUT = 0;
export const FormContext = createContext(null);
export const useForm = () => useContext(FormContext);

function Form({
	children,
	nextPage,
	questionId,
	amplitude,
	...props
}) {
	const navigate = useNavigate();
	const analytics = useAnalytics();
	const onChange = useCallback(e => {
		const answer = `undefined.val${e.target.value}`;
		const title = document.querySelector('#question-title')?.textContent || '';
		analytics({
			type: 'OnboardingSurveyQuestionAnswered',
			question_id: questionId,
			amplitude,
			answer,
			title,
			button: ''
		});
		setTimeout(() => {
			navigate(nextPage);
		}, REDIRECT_TIMEOUT);
	}, [navigate, analytics, nextPage, questionId, amplitude]);
	return <StyledForm {...props} onChange={onChange}>
		      
		<FormContext.Provider value={nextPage}>
			        
			{children}
			      
		</FormContext.Provider>
		    
	</StyledForm>;
}

Object.assign(Form, {
	propInfo: {
		questionId: {
			control: 'input',
			category: 'Analytics',
			weight: 1
		},
		amplitude: {
			control: 'input',
			category: 'Analytics',
			weight: 1
		},
		nextPage: {
			control: 'href'
		}
	}
});
export default Form;