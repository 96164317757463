import React from 'react';
import { useOverrides, Override } from '@quarkly/components';
import styled from 'styled-components';
import { Box, Input } from '@quarkly/widgets';
import QuarklycommunityKitLabel from './QuarklycommunityKitLabel';
import { useForm } from './Form';
import SvgIcon from './SvgIcon';
const StyledInput = styled(Input)`
  &:checked + label {
    background: var(--qtheme-background-selectCheckedBg);
  }
`;
const defaultProps = {
	'min-width': '10px',
	'min-height': '10px',
	position: 'relative'
};
const overrides = {
	quarklycommunityKitLabel: {
		kind: 'QuarklycommunityKitLabel',
		props: {
			display: 'flex',
			'align-items': 'center',
			cursor: 'pointer',
			'border-radius': '4px',
			'min-height': '72px',
			padding: '0px 22px',
			gap: '22px',
			'justify-content': 'space-between',
			'transition-duration': '.25s',
			'will-change': 'background',
			'user-select': 'none',
			overflow: 'hidden',
			font: '--singleSelect',
			color: '#141d19',
			background: '#F4F4C2'
		}
	},
	quarklycommunityKitLabelOverride: {
		kind: 'Override',
		props: {
			slot: 'Text',
			as: 'span',
			children: '18-24'
		}
	},
	'Right icon': {
		kind: 'SvgIcon',
		props: {
			icon: 'chevron-left',
			'flex-shrink': '0',
			width: '6px',
			height: '16px',
			transform: 'rotate(180deg)',
			fill: '#141d19',
			'transition-duration': '.2s'
		}
	}
};
const propInfo = {
	val: {
		control: 'input'
	}
};

function Single({
	val,
	...props
}) {
	const form = useForm();
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Box {...rest}>
		      
		<StyledInput
			type="radio"
			position="absolute"
			name="q"
			id={`q-${form}-${val}`}
			value={val}
			opacity={0}
			left={0}
			top={0}
			height={0}
			width={0}
		/>
		      
		<QuarklycommunityKitLabel {...override('quarklycommunityKitLabel')} htmlFor={`q-${form}-${val}`}>
			        
			<Override {...override('quarklycommunityKitLabelOverride')} />
			        
			<SvgIcon {...override('Right icon')} />
			      
		</QuarklycommunityKitLabel>
		      
		{children}
		    
	</Box>;
}

Object.assign(Single, { ...Box,
	defaultProps,
	propInfo,
	overrides
});
export default Single;